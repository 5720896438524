import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Button } from 'client/components/v3/Common/Button';
import { ReduxState } from 'client/reducers';
import { AutomaticStopSaleProductGroupSetting } from 'shared/models/swagger';

import styles from './ActionButton.module.css';
import { DeleteProductGroupModal } from './DeleteProductGroupModal';

type Props = {
  productGroupSetting: AutomaticStopSaleProductGroupSetting;
  lastItemOnList: boolean;
};

export const ActionButton = ({
  productGroupSetting,
  lastItemOnList,
}: Props) => {
  const menuRef = useRef<HTMLUListElement>(null);
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const fieldSetRef = useRef<HTMLFieldSetElement | null>(null);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  useEffect(() => {
    const handleClickOutside = ({ target }: Event) => {
      if (
        showMenu &&
        target instanceof Node &&
        !fieldSetRef?.current?.contains(target)
      ) {
        setShowMenu(false);
      }
    };

    // Add event listeners to document for click outside
    window.document.addEventListener('mousedown', handleClickOutside);
    window.document.addEventListener('touchstart', handleClickOutside);

    return () => {
      // Remove event listeners on cleanup
      window.document.removeEventListener('mousedown', handleClickOutside);
      window.document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showMenu]);

  return (
    <fieldset ref={fieldSetRef} className={styles['p-primary__select']}>
      <div>
        <Button
          uiType="bg"
          size="icon"
          color="white"
          iconBeforeText={
            <i className="c-icon-solid-general-dots-vertical"></i>
          }
          onClick={() => setShowMenu(!showMenu)}
          loading={loading}
        />
      </div>
      <ul
        className={clsx(
          styles['p-primary__select__menu'],
          showMenu && styles['is-active'],
          lastItemOnList && styles['open-above']
        )}
        ref={menuRef}
      >
        <li
          className={styles['p-primary__select__menu__item']}
          onClick={() => {
            setShowMenu(false);
          }}
        >
          <Link
            to={{
              pathname: `/availability/productGroup/edit`,
              state: {
                key: productGroupSetting.key,
              },
            }}
          >
            <div>
              <i className="c-icon-outline-general-edit-05"></i>
              <div>{t('Edit Product Group')}</div>
            </div>
          </Link>
        </li>
        <li
          className={clsx(
            styles['p-primary__select__menu__item'],
            styles['red']
          )}
          onClick={() => setShowModal(true)}
        >
          <div>
            <i className="c-icon-outline-general-trash-03"></i>
            <div>{t('Delete Product Group')}</div>
          </div>
        </li>
      </ul>
      {showModal && (
        <DeleteProductGroupModal
          productGroupKey={productGroupSetting?.key || ''}
          onClose={() => {
            setShowModal(false);
            setShowMenu(false);
          }}
        />
      )}
    </fieldset>
  );
};
