import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import { ReduxState } from 'client/reducers';

type Props = {
  productGroupKey: string;
  onClose: () => void;
};

export const DeleteProductGroupModal = ({
  productGroupKey,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const loading = useSelector(
    (state: ReduxState) => state.organizations.loading
  );

  return (
    <Modal
      title={t('Delete Product Group')}
      open={true}
      onClose={onClose}
      insertAtRoot={true}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            onClick={() => {
              onClose();
            }}
            color="white"
          />
          <Button
            text={t('OK')}
            onClick={async () => {
              const orgId = activeUserOrganization?.id || '';
              try {
                const autoStopSetting =
                  activeUserOrganization?.automatic_stop_sale_product_group_settings ??
                  [];
                const updatedCustomerAutoTags = autoStopSetting.filter(
                  (group) => group.key !== productGroupKey
                );
                await dispatch(
                  updateOrganization(orgId, 'SUPPLIER', {
                    automatic_stop_sale_product_group_settings:
                      updatedCustomerAutoTags,
                  })
                );
                await onClose();
              } catch (e) {
                console.error(e);
              }
            }}
            color="red"
            loading={loading}
          />
        </>
      }
    >
      {t('Are you sure you want to delete?')}
    </Modal>
  );
};
