import React, { Suspense } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ETicketRedemptionCountDailyUses } from 'client/pages/ETicket/ETicketRedemptionCountDailyUses/ETicketRedemptionCountDailyUses';
import { config } from 'client/config';
import { WebSocketConnectionProvider } from 'client/contexts/WebsocketConnectionContext';
import { PrivateRoute } from 'client/components/PrivateRoute';
import { hasSubscription } from 'client/libraries/util/subscriptions';
import { Login } from 'client/loggedOutPages/Login/Login';
import { Oops } from 'client/loggedOutPages/Oops/Oops';
import { Terms } from 'client/loggedOutPages/Terms/Terms';
import { Dash } from 'client/pages/Dash';
import { Dashboard } from 'client/pages/Dashboard/Dashboard';
import { ReservationDashBoard as ReservationDashBoardV3 } from 'client/pages/v3/Reservation/ReservationDashBoard/ReservationDashBoard';
import { BookingForm } from 'client/pages/BookingForm/BookingForm';
import { FreeFormatBookingForm } from 'client/pages/BookingForm/FreeFormatBookingForm';
import { AccountList } from 'client/pages/AccountList/AccountList';
import { BillingPeriod as BillingPeriodRevamped } from 'client/pages/BillingPeriodRevamped';
import { BookingWidgetCustomize } from 'client/pages/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize';
import { BookingWidgetTopPage } from 'client/pages/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage';
import { BookingWidgetCustomTopPageEditor } from 'client/pages/BookingWidget/BookingWidgetCustomTopPage/BookingWidgetCustomTopPageEditor';
import { GoogleMyBusiness } from 'client/pages/BookingWidget/GoogleMyBusiness/GoogleMyBusiness';
import { BookingWidgetWebSiteIntegration } from 'client/pages/BookingWidget/BookingWidgetWebSiteIntegration/BookingWidgetWebSiteIntegration';
import { DigitalGuidanceLocationList } from 'client/pages/DigitalGuidance/DigitalGuidanceLocationList/DigitalGuidanceLocationList';
import { DigitalGuidancePageList } from 'client/pages/DigitalGuidance/DigitalGuidancePageList/DigitalGuidancePageList';
import { DigitalGuidanceCouponList } from 'client/pages/DigitalGuidance/DigitalGuidanceCouponList/DigitalGuidanceCouponList';
import { DigitalGuidanceStampRallyList } from 'client/pages/DigitalGuidance/DigitalGuidanceStampRallyList/DigitalGuidanceStampRallyList';
import { DigitalGuidanceSettings } from 'client/pages/DigitalGuidance/DigitalGuidanceSettings/DigitalGuidanceSettings';
import { BulkEmailNotification } from 'client/pages/Inquiry/BulkEmailNotification/BulkEmailNotification';
import { BulkSMSNotification } from 'client/pages/Inquiry/BulkSMSNotification/BulkSMSNotification';
import { InquiryList } from 'client/pages/Inquiry/InquiryList/InquiryList';
import { InquiryDetails } from 'client/pages/Inquiry/InquiryDetails/InquiryDetails';
import { InquirySettings } from 'client/pages/Inquiry/InquirySettings/InquirySettings';
import { SmsBilling } from 'client/pages/Inquiry/SmsBilling/SmsBilling';
import { SubmittedSurveyList } from 'client/pages/Survey/SubmittedSurveyList/SubmittedSurveyList';
import { SurveyTemplateList } from 'client/pages/Survey/SurveyTemplateList/SurveyTemplateList';
import { SurveyTemplateEditor } from 'client/pages/Survey/SurveyTemplateEditor/SurveyTemplateEditor';
import { SurveyTemplateResults } from 'client/pages/Survey/SurveyTemplateResults/SurveyTemplateResults';
import { SurveySettings } from 'client/pages/Survey/SurveySettings/SurveySettings';
import { EmailCustomization } from 'client/pages/BookingWidget/EmailCustomization/EmailCustomization';
import { Help } from 'client/pages/Help/Help';
import { ProductEditTutorial } from 'client/pages/Home/Tutorial/ProductEditTutorial/ProductEditTutorial';
import { BookingSitePreview } from 'client/pages/BookingSitePreview/BookingSitePreview';
import { SiteSettingsTutorial } from 'client/pages/Home/Tutorial/SiteSettingsTutorial/SiteSettingsTutorial';
import { Invoice as InvoiceRevamped } from 'client/pages/InvoiceRevamped/Invoice';
import { Manage } from 'client/pages/Manage';
import { ProductDetails } from 'client/pages/ProductDetails/ProductDetails';
import { Manifest } from 'client/pages/Manifest';
import { ManifestCustomize } from 'client/pages/ManifestCustomize';
import { CheckinReservation } from 'client/pages/CheckinReservation/CheckinReservation';
import { QrCodeReader } from 'client/pages/QrCodeReader/QrCodeReader';
import { DispatchCustomize } from 'client/pages/DispatchCustomize/DispatchCustomize';
import { ReservationSearch } from 'client/pages/ReservationSearch/ReservationSearch';
import { ReservationList as ReservationListV3 } from 'client/pages/v3/Reservation/ReservationList/ReservationList';
import { ReservationDataDownload as ReservationDataDownloadV3 } from 'client/pages/v3/Reservation/ReservationDataDownload/ReservationDataDownload';
import { ReservationReport as ReservationReportV3 } from 'client/pages/v3/Reservation/ReservationReport/ReservationReport';
import { ReservationDetailsWrapper as ReservationDetailsV3 } from 'client/pages/v3/Reservation/ReservationDetails/ReservationDetailsWrapper';
import { ProductDetails as ProductDetailsV3 } from 'client/pages/v3/Product/ProductDetails/ProductDetails';
import { ProductEdit as ProductEditV3 } from 'client/pages/v3/Product/ProductEdit/ProductEdit';
import { ProductCreate as ProductCreateV3 } from 'client/pages/v3/Product/ProductCreate/ProductCreate';
import { ProductTranslation as ProductTranslationV3 } from 'client/pages/v3/Product/ProductTranslation/ProductTranslation';
import { ProductList as ProductListV3 } from 'client/pages/v3/Product/ProductList/ProductList';
import { ReservationBooking } from 'client/pages/v3/Reservation/ReservationCreate/Booking/ReservationBooking';
import { AvailabilityWeekly } from 'client/pages/v3/Availability/AvailabilityWeekly/AvailabilityWeekly';
import { AvailabilityMonthly } from 'client/pages/v3/Availability/AvailabilityMonthly/AvailabilityMonthly';
import { ProductGroup } from 'client/pages/v3/Availability/ProductGroup/ProductGroup';
import { ProductGroupSetting } from 'client/pages/v3/Availability/ProductGroup/ProductGroupSetting/ProductGroupSetting';
import { ManifestDaily } from 'client/pages/v3/Manifest/ManifestDaily/ManifestDaily';
import { ManifestResourceAssignment } from 'client/pages/v3/Manifest/ManifestResourceAssignment/ManifestResourceAssignment';
import { ManifestCustomize as ManifestCustomizeV3 } from 'client/pages/v3/Manifest/ManifestCustomize/ManifestCustomize';
import { ManifestEditResource } from 'client/pages/v3/Manifest/ManifestEditResource/ManifestEditResource';
import { CustomerSettings as CustomerSettingsV3 } from 'client/pages/v3/Customer/CustomerSettings/CustomerSettings';
import { Reservation } from 'client/pages/Reservation/Reservation';
import { ReservationDemo } from 'client/pages/ReservationDemo/ReservationDemo';
import { ReservationVoucher } from 'client/pages/ReservationVoucher/ReservationVoucher';
import { ReservationVoucherPreview } from 'client/pages/ReservationVoucherPreview/ReservationVoucherPreview';
import { ReservationReport } from 'client/pages/ReservationReport/ReservationReport';
import { ReservationDataDownload } from 'client/pages/ReservationDataDownload/ReservationDataDownload';
import { ActimReservationList } from 'client/pages/ActimReservationList/ActimReservationList';
import { JaranImport } from 'client/pages/JaranImport/JaranImport';
import { AccessReport } from 'client/pages/AccessReport/AccessReport';
import { AccessReport as AccessReportV3 } from 'client/pages/v3/BookingWidget/AccessReport/AccessReport';
import { BookingWidgetCustomize as BookingWidgetCustomizeV3 } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize';
import { BookingWidgetSettings as BookingWidgetSettingsV3 } from 'client/pages/v3/BookingWidget/BookingWidgetSettings/BookingWidgetSettings';
import { BookingWidgetTopPage as BookingWidgetTopPageV3 } from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage';
import { WebSiteIntegration as WebSiteIntegrationV3 } from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration';
import { ReviewSearch } from 'client/pages/ReviewSearch/ReviewSearch';
import { ReviewEmbed } from 'client/pages/ReviewEmbed/ReviewEmbed';
import { ReviewSettings } from 'client/pages/ReviewSettings/ReviewSettings';
import { PrivateMarketplaceGeneralSettings } from 'client/pages/PrivateMarketplace/PrivateMarketplaceGeneralSettings/PrivateMarketplaceGeneralSettings';
import { PrivateMarketplaceRecommendedProducts } from 'client/pages/PrivateMarketplace/PrivateMarketplaceRecommendedProducts/PrivateMarketplaceRecommendedProducts';
import { PrivateMarketplaceProductRanking } from 'client/pages/PrivateMarketplace/PrivateMarketplaceProductRanking/PrivateMarketplaceProductRanking';
import { PrivateMarketplaceProductCollectionPages } from 'client/pages/PrivateMarketplace/PrivateMarketplaceProductCollectionPages/PrivateMarketplaceProductCollectionPages';
import { PrivateMarketplaceMarketingAutomation } from 'client/pages/PrivateMarketplace/PrivateMarketplaceMarketingAutomation/PrivateMarketplaceMarketingAutomation';
import { MonthlyAvailability } from 'client/pages/Availability/MonthlyAvailability';
import { WeeklyAvailability } from 'client/pages/Availability/WeeklyAvailability';
import { CustomRangeAvailability } from 'client/pages/Availability/CustomRangeAvailability';
import { SignUpDefaults } from 'client/pages/SignUpDefaults/SignUpDefaults';
import { CreateNewProduct } from 'client/pages/CreateNewProduct/CreateNewProduct';
import { ProductEditor } from 'client/pages/ProductEditor/ProductEditor';
import { ProductList } from 'client/pages/ProductList';
import { ProductPriceList } from 'client/pages/ProductPriceList/ProductPriceList';
import { PromotionList } from 'client/pages/PromotionList/PromotionList';
import { ProductPromotions as ProductPromotionsV3 } from 'client/pages/v3/Product/ProductPromotions/ProductPromotions';
import { Settings as SettingsV3 } from 'client/pages/v3/Settings/Settings';
import { Settings } from 'client/pages/Settings';
import { SiteSettingsForm } from 'client/components/SiteSettingsForm/SiteSettingsForm';
import { EditProductAgentReferences } from 'client/pages/EditProductAgentReferences/EditProductAgentReferences';
import { NewUser } from 'client/pages/NewUser';
import { SwaggerUI } from 'client/pages/SwaggerUI/SwaggerUI';
import { AboutUsEditor } from 'client/pages/EssentialPages/AboutUsEditor/AboutUsEditor';
import { FaqEditor } from 'client/pages/EssentialPages/FaqEditor/FaqEditor';
import { WaiverSettings } from 'client/pages/WaiverSettings/WaiverSettings';
import { WaiverTemplateList } from 'client/pages/WaiverTemplateList/WaiverTemplateList';
import { WaiverList } from 'client/pages/WaiverList/WaiverList';
import { WaiverTemplateEditor } from 'client/pages/WaiverTemplateList/WaiverTemplateEditor';
import { Covid19Editor } from 'client/pages/EssentialPages/Covid19Editor/Covid19Editor';
import { CustomPages } from 'client/pages/EssentialPages/CustomPages/CustomPages';
import { EditCustomPage } from 'client/components/EditCustomPage/EditCustomPage';
import { EditCustomPage as EditCustomPageV3 } from 'client/pages/v3/BookingWidget/EssentialPages/EditCustomPage/EditCustomPage';
import { EssentialPages } from 'client/pages/EssentialPages/EssentialPages';
import { EssentialPages as EssentialPagesV3 } from 'client/pages/v3/BookingWidget/EssentialPages/EssentialPages';
import { GuideList } from 'client/pages/GuideList/GuideList';
import { GuideSchedule } from 'client/pages/GuideSchedule/GuideSchedule';
import { Schedule } from 'client/pages/Schedule/Schedule';
import { ResourceAssignment } from 'client/pages/ResourceAssignment/ResourceAssignment';
import { MediaDownloadOrderList } from 'client/pages/MediaDownload/MediaDownloadOrderList/MediaDownloadOrderList';
import { MediaDownloadPriceList } from 'client/pages/MediaDownload/MediaDownloadPriceList/MediaDownloadPriceList';
import { MediaDownloadSalesPageList } from 'client/pages/MediaDownload/MediaSalesPageList/MediaDownloadSalesPageList';
import { MediaDownloadSettings } from 'client/pages/MediaDownload/MediaDownloadSettings/MediaDownloadSettings';
import { CheckinRecordList } from 'client/pages/CheckinRecordList/CheckinRecordList';
import { ResourceAvailability } from 'client/pages/ResourceAvailability/ResourceAvailability';
import { SystemFeeReservations } from 'client/pages/SystemFee/SystemFeeReservations/SystemFeeReservations';
import { SystemFeeInvoiceDetails } from 'client/pages/SystemFee/SystemFeeInvoiceDetails/SystemFeeInvoiceDetails';
import { SystemFeeSubscriptions } from 'client/pages/SystemFee/SystemFeeSubscriptions/SystemFeeSubscriptions';
import { SystemFeeSettings } from 'client/pages/SystemFee/SystemFeeSettings/SystemFeeSettings';
import { SystemFeeSettings as SystemFeeSettingsV3 } from 'client/pages/v3/SystemFee/SystemFeeSettings/SystemFeeSettings';
import { SystemFeeSubscriptions as SystemFeeSubscriptionsV3 } from 'client/pages/v3/SystemFee/SystemFeeSubscriptions/SystemFeeSubscriptions';
import { SystemFeeReservations as SystemFeeReservationsV3 } from 'client/pages/v3/SystemFee/SystemFeeReservations/SystemFeeReservations';
import { SystemFeeInvoiceDetails as SystemFeeInvoiceDetailsV3 } from 'client/pages/v3/SystemFee/SystemFeeInvoiceDetails/SystemFeeInvoiceDetails';
import { ActivityPlanSystemFeeInvoiceDetails as ActivityPlanSystemFeeInvoiceDetailsV3 } from 'client/pages/v3/SystemFee/SystemFeeInvoiceDetails/ActivityPlanSystemFeeInvoiceDetails';
import { ActivityPlanSystemFeeReservations as ActivityPlanSystemFeeReservationsV3 } from 'client/pages/v3/SystemFee/SystemFeeReservations/ActivityPlanSystemFeeReservations';
import { RedeemedRecordList } from 'client/pages/RedeemedRecordList/RedeemedRecordList';
import { ETicketLocationList } from 'client/pages/ETicket/ETicketLocationList/ETicketLocationList';
import { FootprintList } from 'client/pages/ETicket/FootprintList/FootprintList';
import { ETicketQrCodeList } from 'client/pages/ETicket/ETicketQrCode/ETicketQrCodeList';
import { ETicketDailyUses } from 'client/pages/ETicket/ETicketDailyUses/ETicketDailyUses';
import { CustomerSettings } from 'client/pages/Customer/CustomerSettings/CustomerSettings';
import { CustomerList } from 'client/pages/Customer/CustomerList/CustomerList';
import { CustomerDetails } from 'client/pages/Customer/CustomerDetails/CustomerDetails';
import { NewsletterList } from 'client/pages/NewsletterList/NewsletterList';
import { NewsletterSegmentList } from 'client/pages/NewsletterSegmentList/NewsletterSegmentList';
import { NewsletterSegmentEditor } from 'client/pages/NewsletterSegmentEditor/NewsletterSegmentEditor';
import { SendNewsletterWizard } from 'client/pages/SendNewsletterWizard/SendNewsletterWizard';
import { NewsletterReport } from 'client/pages/NewsletterReport/NewsletterReport';
import { ChatWidget } from 'client/components/ChatWidget/ChatWidget';
import { SeatAssignment } from 'client/components/Seat/SeatAssignment';
import { EquipmentList } from 'client/pages/EquipmentList/EquipmentList';
import { EquipmentEditor } from 'client/pages/EquipmentEditor/EquipmentEditor';
import { SeatEditor } from 'client/pages/SeatEditor/SeatEditor';
import { SeatManagementBookingSourceGroups } from 'client/pages/SeatManagementBookingSourceGroups/SeatManagementBookingSourceGroups';
import { SeatManagementSettings } from 'client/pages/SeatManagementSettings/SeatManagementSettings';
import { SeatManagementPricing } from 'client/pages/SeatManagementPricing/SeatManagementPricing';
import { SalesOfferStats } from 'client/pages/SalesOfferStats/SalesOfferStats';
import { WeeklySeatAvailability } from 'client/pages/SeatAvailability/WeeklySeatAvailability';
import { MonthlySeatAvailability } from 'client/pages/SeatAvailability/MonthlySeatAvailability';
import { InstantWinList } from 'client/pages/InstantWinList/InstantWinList';
import { InstantWinEventList } from 'client/pages/InstantWinEventList/InstantWinEventList';
import { InstantWinSettings } from 'client/pages/InstantWinSettings/InstantWinSettings';
import { AnnualPassSearch } from 'client/pages/AnnualPassSearch/AnnualPassSearch';
import { AnnualPassDashboard } from 'client/pages/v3/AnnualPass/Dashboard';
import { ExpiredTimeReport } from 'client/pages/ExpiredTimeReport/ExpiredTimeReport';
import { ETicketFootprintDateCount } from 'client/pages/ETicketFootprintDateCount/ETicketFootprintDateCount';
import { ETicketFootprintReservations } from 'client/pages/ETicketFootprintReservations/ETicketFootprintReservations';
import { PartnershipSupplierProducts } from 'client/pages/PartnershipSupplierProducts/PartnershipSupplierProducts';
import { PartnershipPackageProducts } from 'client/pages/PartnershipPackageProducts/PartnershipPackageProducts';
import { PartnershipReservationSearch } from 'client/pages/PartnershipReservationSearch/PartnershipReservationSearch';
import { PartnershipReservation } from 'client/pages/PartnershipReservation/PartnershipReservation';
import { PartnershipAgentSystemFeeInvoices } from 'client/pages/PartnershipAgentSystemFeeInvoices/PartnershipAgentSystemFeeInvoices';
import { PartnershipAgentSystemFeeLineItems } from 'client/pages/PartnershipAgentSystemFeeLineItems/PartnershipAgentSystemFeeLineItems';
import { PartnershipCheckinRecordList } from 'client/pages/PartnershipCheckinRecordList/PartnershipCheckinRecordList';
import { PartnershipReservationBooking } from 'client/pages/PartnershipReservationBooking/PartnershipReservationBooking';
import { PartnershipSystemFeeReservations } from 'client/pages/PartnershipSystemFee/PartnershipSystemFeeReservations/PartnershipSystemFeeReservations';
import { PartnershipSystemFeeInvoiceDetails } from 'client/pages/PartnershipSystemFee/PartnershipSystemFeeInvoiceDetails/PartnershipSystemFeeInvoiceDetails';
import { CrmDashboard } from 'client/pages/CrmDashboard/CrmDashboard';
import { DigitalMapAccessReport } from 'client/pages/v3/BookingWidget/DigitalMapAccessReport/DigitalMapAccessReport';
import { ChatDataSourceSettings } from 'client/components/Chat/ChatDataSourceSettings';
import { ETicketEdit } from 'client/pages/v3/Product/ETicketEdit/ETicketEdit';
import { ETicketTranslation } from 'client/pages/v3/Product/ETicketTranslation/ETicketTranslation';
import { EquipmentScheduleList } from 'client/pages/v3/Seat/EquipmentScheduleList/EquipmentScheduleList';
import { EquipmentScheduleEditor } from 'client/pages/v3/Seat/EquipmentScheduleEditor/EquipmentScheduleEditor';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
  activeUserOrganizationSelector,
  loggedInAccountIsNutmegAdminSelector,
} from 'client/reducers/user';
import {
  hasCustomUserRoleReadPermissions,
  hasCustomUserRoleWritePermissions,
} from 'client/libraries/util/customUserPermissions';
import { isSignedIn } from 'client/libraries/cognito';
import {
  commissionsTableEnabledForAccount,
  dashEnabledForAccount,
  homeEnabledForAccount,
  operationAllowed,
  availabilityTableEnabledForAccount,
  manifestEnabledForAccount,
  promotionsEnabledForAccount,
} from 'shared/models/access';
import { selectedPrivateMarketplaceIndexSelector } from 'client/reducers/privateMarketplace';
import 'client/react-dates/initialize';
import { Analytics } from 'client/components/Analytics/Analytics';
import { ErrorBoundary } from 'client/components/ErrorBoundary';
import { InvoiceDetailsRevamped } from 'client/pages/InvoiceDetailsRevamped';
import { NewsletterEmail } from 'client/pages/NewsletterEmail/NewsletterEmail';
import { SiteTemplateTutorial } from 'client/pages/Home/Tutorial/SiteTemplateTutorial/SiteTemplateTutorial';
import { SalesOfferEditor } from 'client/pages/SalesOfferEditor/SalesOfferEditor';
import { SalesOfferList } from 'client/pages/SalesOfferList/SalesOfferList';
import type { ReduxState } from 'client/reducers';
import type { Account, Organization } from 'shared/models/swagger';
import '../react-table/overrides.css';
import { ReservationCreate } from 'client/pages/v3/Reservation/ReservationCreate/CreateViaAvailability/ReservationCreate';
import { SignUp } from 'client/loggedOutPages/SignUp/SignUp';
import { Home } from 'client/pages/v3/Home/Home';
import { isOnSiteJourneyAnalyticsSupplier } from 'client/libraries/util/onSiteJourneyAnalytics';
import { InternalChat } from 'client/components/InternalChat/InternalChat';

import { NotificationTemplateList } from './NotificationTemplateList/NotificationTemplateList';
import { NotificationTemplateEditor } from './NotificationTemplateEditor/NotificationTemplateEditor';
import { NotificationSettings } from './NotificationSettings/NotificationSettings';
import { GroupBookingTemplateList } from './GroupBookingTemplateList/GroupBookingTemplateList';
import { GroupBookingTemplateEditor } from './GroupTemplateEditor/GroupTemplateEditor';
import { GroupReservationList } from './GroupReservationList/GroupReservationList';
import { DigitalMapList } from './DigitalMapList/DigitalMapList';
import { DigitalMapEditor } from './DigitalMapEditor/DigitalMapEditor';
import { JourneyAnalyticsCustomerList } from './JourneyAnalyticsCustomerList/JourneyAnalyticsCustomerList';
import { OnSiteJourneyAnalyticsCustomerList } from './JourneyAnalyticsCustomerList/OnSiteJourneyAnalyticsCustomerList';
import { JourneyAnalyticsCustomerDetails } from './JourneyAnalyticsCustomerDetails/JourneyAnalyticsCustomerDetails';
import { OnSiteJourneyAnalyticsCustomerDetails } from './JourneyAnalyticsCustomerDetails/OnSiteJourneyAnalyticsCustomerDetails';
import { MarketingAutomationContentList } from './MarketingAutomationContentList/MarketingAutomationContentList';
import { MarketingAutomationCampaignList } from './MarketingAutomationCampaignList/MarketingAutomationCampaignList';
import { MarketingAutomationSegmentList } from './MarketingAutomationSegmentList/MarketingAutomationSegmentList';
import { MarketingAutomationSegmentEditor } from './MarketingAutomationSegmentEditor/MarketingAutomationSegmentEditor';
import { MarketingAutomationTriggerConditionEditor } from './MarketingAutomationTriggerConditionEditor/MarketingAutomationTriggerConditionEditor';
import { MarketingAutomationTriggerConditionList } from './MarketingAutomationTriggerConditionList/MarketingAutomationTriggerConditionList';
import { MarketingAutomationContentEditor } from './MarketingAutomationContentEditor/MarketingAutomationContentEditor';
import { MarketingAutomationCampaignEditor } from './MarketingAutomationCampaignEditor/MarketingAutomationCampaignEditor';
import { MarketingAutomationCampaignReport } from './MarketingAutomationCampaignReport/MarketingAutomationCampaignReport';
import { MarketingAutomationCampaignEmail } from './MarketingAutomationCampaignEmail/MarketingAutomationCampaignEmail';
import { JourneyAnalyticsDashboard } from './JourneyAnalyticsDashboard/JourneyAnalyticsDashboard';
import { OnSiteJourneyAnalyticsDashboard } from './JourneyAnalyticsDashboard/OnSiteJourneyAnalyticsDashboard';
import { MarketingAutomationDashboard } from './MarketingAutomationDashboard/MarketingAutomationDashboard';
import { JourneyAnalyticsSettings } from './JourneyAnalyticsSettings/JourneyAnalyticsSettings';
import { MobileOrderSettings } from './MobileOrder/MobileOrderSettings/MobileOrderSettings';
import { Loading } from './Loading';
import { MobileOrderDashboard } from './MobileOrder/MobileOrderDashboard/MobileOrderDashboard';
import { MobileOrderList } from './MobileOrder/MobileOrderList/MobileOrderList';
import { MobileOrderReviewList } from './MobileOrder/MobileOrderReviewList/MobileOrderReviewList';
import { RestaurantList } from './MobileOrder/RestaurantList/RestaurantList';
import { MobileOrderPromotionList } from './MobileOrder/MobileOrderPromotionList/MobileOrderPromotionList';
import { RestaurantEditor } from './MobileOrder/RestaurantEditor/RestaurantEditor';
import { MenuEditor } from './MobileOrder/MenuEditor/MenuEditor';
import { SiteControlWidgetList } from './SiteControlWidget/SiteControlWidgetList/SiteControlWidgetList';
import { SiteControlWidgetEditor } from './SiteControlWidget/SiteControlWidgetEditor/SiteControlWidgetEditor';
import { JourneyAnalyticsEmbedCode } from './JourneyAnalyticsEmbedCode/JourneyAnalyticsEmbedCode';
import { SiteControlWidgetDashboard } from './SiteControlWidget/SiteControlWidgetDashboard/SiteControlWidgetDashboard';
import { SiteControlWidgetReport } from './SiteControlWidget/SiteControlWidgetReport/SiteControlWidgetReport';
import { SurveyDashboard } from './Survey/SurveyDashboard/SurveyDashboard';
import { WaitTimeList } from './WaitTimeList/WaitTimeList';
import { WaitTimeManager } from './WaitTimeManager/WaitTimeManager';
import { DigitalMapDashboard } from './DigitalMapDashboard/DigitalMapDashboard';
import { DigitalMapGuestJourneyList } from './DigitalMapGuestJourneyList/DigitalMapGuestJourneyList';
import { DigitalMapGuestJourneyDetails } from './DigitalMapGuestJourneyDetails/DigitalMapGuestJourneyDetails';
import { JourneyAnalyticsConversionPath } from './JourneyAnalyticsConversionPath/JourneyAnalyticsConversionPath';
import { DigitalMapSettings } from './DigitalMapSettings/DigitalMapSettings';
import { BusRouteList } from './BusRouteList/BusRouteList';
import { JourneyAnalyticsPrivacyPolicies } from './JourneyAnalyticsPrivacyPolicies/JourneyAnalyticsPrivacyPolicies';

const LazyKitchen = React.lazy(() => import('./MobileOrder/Kitchen/Kitchen'));

const homeRoute = (
  activeUser?: Account | null,
  activeOrganization?: Organization | null
): string => {
  if (activeUser?.role === 'GUIDE') {
    return '/schedules';
  }

  if (activeUser && !hasCustomUserRoleReadPermissions(activeUser, 'HOME')) {
    return '/settings';
  }

  // Redirect to home
  if (homeEnabledForAccount(activeUser)) {
    return '/home';
  }
  if (!isTutorialCompleted(activeOrganization)) {
    return '/home';
  }

  return '/reservations';
};

const isTutorialCompleted = (
  activeOrganization?: Organization | null
): boolean => {
  return (
    activeOrganization?.tutorial_stage === 'COMPLETED' ||
    activeOrganization?.tutorial_stage === undefined
  );
};

const App = () => {
  const activeUser = useSelector(activeUserSelector);

  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const isAuthenticated = useSelector((state: ReduxState) =>
    isSignedIn(state.user.cognito)
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const selectedPrivateMarketplaceIndex = useSelector(
    selectedPrivateMarketplaceIndexSelector
  );
  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );
  const pmpDataKey = `${selectedPrivateMarketplaceIndex}-${
    activeOrganization?.id ?? ''
  }`;
  const tutorialCompleted = isTutorialCompleted(activeOrganization);

  return (
    <Router>
      <ErrorBoundary>
        <WebSocketConnectionProvider>
          {isAuthenticated && <ChatWidget />}
          {isAuthenticated &&
            loggedInAccountIsNutmegAdmin &&
            config.enableChatbotInternal && <InternalChat />}
          <Route path="/" component={Analytics} />
          <Switch>
            <Route
              exact
              path="/"
              render={() =>
                isAuthenticated &&
                activeUser &&
                activeUser.organization_id !== '' ? (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/new-user" component={NewUser} />
            <Route exact path="/ja/terms" component={Terms} />
            <PrivateRoute exact path="/home">
              {hasCustomUserRoleReadPermissions(activeUser, 'HOME') ? (
                <Home />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/helps/:id+">
              <Help />
            </PrivateRoute>
            <PrivateRoute exact path="/home/tutorial/product">
              <ProductEditTutorial />
            </PrivateRoute>
            <PrivateRoute exact path="/home/tutorial/settings">
              <SiteSettingsTutorial />
            </PrivateRoute>
            <PrivateRoute exact path="/home/tutorial/sitetemplate">
              <SiteTemplateTutorial />
            </PrivateRoute>
            <PrivateRoute
              hideMenu
              hideFrame
              exact
              path="/home/tutorial/reservation"
            >
              <BookingSitePreview />
            </PrivateRoute>
            <PrivateRoute exact path="/bookingWidget/general">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.SETTINGS'
              ) ? (
                <SiteSettingsForm />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Booking Widget Settings V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/bookingWidget/general-v3">
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'BOOKING_SITE.CUSTOMIZE'
                ) ? (
                  <BookingWidgetSettingsV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Booking Widget Customize V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/bookingWidget/customize-v3">
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'BOOKING_SITE.CUSTOMIZE'
                ) ? (
                  <BookingWidgetCustomizeV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/bookingWidget/customize">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.CUSTOMIZE'
              ) ? (
                <BookingWidgetCustomize />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Booking Widget TopPage V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/bookingWidget/topPage-v3">
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'BOOKING_SITE.TOP_PAGE'
                ) ? (
                  <BookingWidgetTopPageV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/bookingWidget/topPage">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.TOP_PAGE'
              ) ? (
                <BookingWidgetTopPage />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/bookingWidget/customTopPage">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.TOP_PAGE'
              ) ? (
                <BookingWidgetCustomTopPageEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/bookingWidget/emails">
              {hasSubscription(
                activeOrganization,
                'feature-email-customization'
              ) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.EMAILS'
              ) ? (
                <EmailCustomization />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/pmp/general">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'BOOKING_SITE') ? (
                <PrivateMarketplaceGeneralSettings key={pmpDataKey} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Booking Widget WebsiteIntegration V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/bookingWidget/websiteintegration-v3">
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'BOOKING_SITE.WEBSITEINTEGRATION'
                ) ? (
                  <WebSiteIntegrationV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/bookingWidget/websiteintegration">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.WEBSITEINTEGRATION'
              ) ? (
                <BookingWidgetWebSiteIntegration />
              ) : (
                <Redirect to={homeRoute()} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/bookingWidget/gmb">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.GOOGLE_MY_BUSINESS'
              ) ? (
                <GoogleMyBusiness />
              ) : (
                <Redirect to={homeRoute()} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/pmp/ranking">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'BOOKING_SITE') ? (
                <PrivateMarketplaceProductRanking key={pmpDataKey} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/pmp/recommended">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'BOOKING_SITE') ? (
                <PrivateMarketplaceRecommendedProducts key={pmpDataKey} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/pmp/categories">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'BOOKING_SITE') ? (
                <PrivateMarketplaceProductCollectionPages
                  key={pmpDataKey}
                  collectionType="CATEGORY"
                />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/pmp/featured">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'BOOKING_SITE') ? (
                <PrivateMarketplaceProductCollectionPages
                  key={pmpDataKey}
                  collectionType="FEATURE"
                />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/pmp/ma">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'BOOKING_SITE') ? (
                <PrivateMarketplaceMarketingAutomation key={pmpDataKey} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/reservations/dash">
              {dashEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.DASHBOARD'
              ) ? (
                <>
                  {activeUser?.organization_type === 'SUPPLIER' ? (
                    <Dashboard />
                  ) : (
                    <Dash />
                  )}
                </>
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Reservation dash board page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/reservations/dash-v3">
                {dashEnabledForAccount(activeUser) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'RESERVATION.DASHBOARD'
                ) ? (
                  <>
                    {activeUser?.organization_type === 'SUPPLIER' ? (
                      <ReservationDashBoardV3 />
                    ) : (
                      <Dash />
                    )}
                  </>
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/reservations/reports">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.REPORTS'
              ) ? (
                <ReservationReport />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/reservations/download">
              {operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.DATA_DOWNLOAD'
              ) ? (
                <ReservationDataDownload />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/reservations/actimReservations">
              {operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.ACTIM_RESERVATIONS'
              ) ? (
                <ActimReservationList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute
              exact
              hideMenu
              hideFrame
              path="/reservations/voucherpreview"
            >
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION') ? (
                <ReservationVoucherPreview />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/reservations">
              {operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.LIST'
              ) ? (
                <ReservationSearch />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>

            {/* Reservation list page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/reservations-v3">
                {operationAllowed(activeUser, 'read', 'reservations') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'RESERVATION.LIST'
                ) ? (
                  <ReservationListV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Reservation data download page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/reservations/download-v3">
                {operationAllowed(activeUser, 'read', 'reservations') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'RESERVATION.DATA_DOWNLOAD'
                ) ? (
                  <ReservationDataDownloadV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Reservation reports page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/reservations/reports-v3">
                {tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'RESERVATION.REPORTS'
                ) ? (
                  <ReservationReportV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Reservation details page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/reservations-v3/:id">
                {operationAllowed(activeUser, 'read', 'reservations') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'RESERVATION.LIST'
                ) ? (
                  <ReservationDetailsV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Reservation create page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute path="/reservations/new-v3">
                {tutorialCompleted &&
                operationAllowed(activeUser, 'write', 'reservations') ? (
                  <ReservationCreate />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Reservation change page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute
                exact
                path="/products/:productID/instances/:instanceID/book-v3"
              >
                {tutorialCompleted &&
                operationAllowed(activeUser, 'write', 'reservations') ? (
                  <ReservationBooking />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Product list page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/products-v3">
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'PRODUCT.LIST'
                ) ? (
                  <ProductListV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Product details page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/products-v3/:id">
                {operationAllowed(activeUser, 'read', 'products') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(activeUser, 'PRODUCT.LIST') ? (
                  <ProductDetailsV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Product edit page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/products/:id/edit-v3">
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'PRODUCT.LIST'
                ) ? (
                  <ProductEditV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/products/:id/edit/eticket">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST') ? (
                <ETicketEdit />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/products/:id/translate/eticket">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST') ? (
                <ETicketTranslation />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Product create page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/products/new-v3">
                {operationAllowed(activeUser, 'write', 'products') &&
                tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'PRODUCT.LIST'
                ) ? (
                  <ProductCreateV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Product copy page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/products/:id/copy-v3">
                {operationAllowed(activeUser, 'write', 'products') &&
                tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'PRODUCT.LIST'
                ) ? (
                  <ProductCreateV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Product translation page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/products/:id/translation-v3">
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'PRODUCT.LIST'
                ) ? (
                  <ProductTranslationV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Availability weekly page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/availability/weekly-v3">
                {availabilityTableEnabledForAccount(activeUser) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'AVAILABILITY.WEEKLY'
                ) ? (
                  <AvailabilityWeekly />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Availability monthly page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/availability/monthly-v3">
                {availabilityTableEnabledForAccount(activeUser) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'AVAILABILITY.MONTHLY'
                ) ? (
                  <AvailabilityMonthly />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Manifest daily page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/manifests-v3">
                {manifestEnabledForAccount(activeUser) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'MANIFEST.DAILY'
                ) ? (
                  <>
                    <ManifestDaily />
                  </>
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Manifest assignment (dispatch) page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/manifests/assignments-v3">
                {manifestEnabledForAccount(activeUser) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'MANIFEST.RESOURCE_ASSIGNMENT'
                ) ? (
                  <ManifestResourceAssignment />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Manifest customize page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/manifests/customize-v3">
                {manifestEnabledForAccount(activeUser) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'MANIFEST.CUSTOMIZE'
                ) ? (
                  <ManifestCustomizeV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            {/* Manifest edit resource page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/manifests/resources-v3">
                {manifestEnabledForAccount(activeUser) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'MANIFEST.EDIT_RESOURCES'
                ) ? (
                  <ManifestEditResource />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}

            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/customers/settings-v3">
                {hasSubscription(
                  activeOrganization,
                  'feature-customer-ledger'
                ) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'CUSTOMER_LEDGER.SETTINGS'
                ) ? (
                  <CustomerSettingsV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}

            <PrivateRoute exact path="/reservations/demo">
              {operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION') ? (
                <ReservationDemo />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/reservations/:id">
              {operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.LIST'
              ) ? (
                <Reservation />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/checkin/:id">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION') ? (
                <CheckinReservation />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/qrreader">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION') ? (
                <QrCodeReader />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute
              exact
              hideMenu
              hideFrame
              path="/reservations/:id/voucher"
            >
              {operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'RESERVATION') ? (
                <ReservationVoucher />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/manifests">
              {manifestEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'MANIFEST.DAILY') ? (
                <>
                  <Manifest />
                </>
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mobileorders/dashboard">
              {tutorialCompleted ? (
                <MobileOrderDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mobileorders/orders">
              {tutorialCompleted ? (
                <MobileOrderList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mobileorders/feedback">
              {tutorialCompleted ? (
                <MobileOrderReviewList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mobileorders/restaurants">
              {tutorialCompleted ? (
                <RestaurantList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mobileorders/restaurants/new">
              {tutorialCompleted ? (
                <RestaurantEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mobileorders/restaurants/:id">
              {tutorialCompleted ? (
                <RestaurantEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/mobileorders/restaurants/:restaurantId/menu"
            >
              {tutorialCompleted ? (
                <MenuEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mobileorders/promotions">
              {tutorialCompleted ? (
                <MobileOrderPromotionList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mobileorders/settings">
              {tutorialCompleted ? (
                <MobileOrderSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute hideMenu hideFrame exact path="/mobileorders/kitchen">
              {tutorialCompleted ? (
                <Suspense fallback={<Loading />}>
                  <LazyKitchen />
                </Suspense>
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/chatbotinternal">
              {config.enableChatbotInternal && tutorialCompleted ? (
                <>
                  {/* TODO: hide this for now */}
                  {/* <InternalChatbot /> */}
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                </>
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/sitecontrolwidgets/dashboard">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-site-hacker') ? (
                <SiteControlWidgetDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/sitecontrolwidgets">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-site-hacker') ? (
                <SiteControlWidgetList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/sitecontrolwidgets/new">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-site-hacker') ? (
                <SiteControlWidgetEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/sitecontrolwidgets/:id/edit">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-site-hacker') ? (
                <SiteControlWidgetEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/sitecontrolwidgets/:id/report">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-site-hacker') ? (
                <SiteControlWidgetReport />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/manifests/assignments">
              {manifestEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MANIFEST.RESOURCE_ASSIGNMENT'
              ) ? (
                <ResourceAssignment />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/manifests/resources">
              {manifestEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MANIFEST.EDIT_RESOURCES'
              ) ? (
                <DispatchCustomize />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/manifests/customize">
              {manifestEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MANIFEST.CUSTOMIZE'
              ) ? (
                <ManifestCustomize />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/manifests/resourceAvailability">
              {(hasSubscription(
                activeOrganization,
                'feature-resource-management'
              ) ||
                hasSubscription(
                  activeOrganization,
                  'feature-vehicle-crew-resource-management'
                )) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MANIFEST.RESOURCE_AVAILABILITY'
              ) ? (
                <ResourceAvailability />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/products">
              {operationAllowed(activeUser, 'read', 'products') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'PRODUCT.LIST') ? (
                <ProductList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/products/new">
              {operationAllowed(activeUser, 'write', 'products') &&
              tutorialCompleted &&
              hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST') ? (
                <CreateNewProduct />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/availability/weekly">
              {availabilityTableEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'AVAILABILITY.WEEKLY'
              ) ? (
                <WeeklyAvailability />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/availability/monthly">
              {availabilityTableEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'AVAILABILITY.MONTHLY'
              ) ? (
                <MonthlyAvailability />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/availability/custom">
              {availabilityTableEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'AVAILABILITY.CUSTOM'
              ) ? (
                <CustomRangeAvailability />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/availability/productGroup">
              {availabilityTableEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'AVAILABILITY.PRODUCT_GROUP'
              ) ? (
                <ProductGroup />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/availability/productGroup/edit">
              {availabilityTableEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'AVAILABILITY.PRODUCT_GROUP'
              ) ? (
                <ProductGroupSetting />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Product Promotions page */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/products/promotions-v3">
                {promotionsEnabledForAccount(activeUser) &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'PRODUCT.PROMOTIONS'
                ) ? (
                  <ProductPromotionsV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/products/promotions">
              {promotionsEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'PRODUCT.PROMOTIONS'
              ) ? (
                <PromotionList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/products/commissions">
              {commissionsTableEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'PRODUCT.COMMISSIONS'
              ) ? (
                <ProductPriceList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/products/:id">
              {operationAllowed(activeUser, 'read', 'products') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'PRODUCT.LIST') ? (
                <ProductDetails />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/products/:id/edit">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST') ? (
                <ProductEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/products/:id/copy">
              {operationAllowed(activeUser, 'write', 'products') &&
              tutorialCompleted &&
              hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.LIST') ? (
                <CreateNewProduct />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/products/:id/editReverseMappings">
              {isNutmegAdmin ||
              (tutorialCompleted &&
                operationAllowed(activeUser, 'write', 'reverseMappings')) ? (
                <EditProductAgentReferences />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/products/:productID/instances/:instanceID/book"
            >
              {tutorialCompleted &&
              operationAllowed(activeUser, 'write', 'reservations') ? (
                <BookingForm />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/freeformat/book">
              {tutorialCompleted &&
              operationAllowed(activeUser, 'write', 'reservations') ? (
                <FreeFormatBookingForm />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/invoices/:id">
              {operationAllowed(activeUser, 'read', 'transactions') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BILLING.INVOICES'
              ) ? (
                <InvoiceDetailsRevamped />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/billing">
              {!config.enableChannelManager &&
              operationAllowed(activeUser, 'read', 'transactions') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BILLING.INVOICES'
              ) ? (
                <InvoiceRevamped />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/billing/period">
              {!config.enableChannelManager &&
              operationAllowed(activeUser, 'write', 'billingPeriod') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BILLING.PERIODS'
              ) ? (
                <BillingPeriodRevamped />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/billing/:id">
              {!config.enableChannelManager &&
              operationAllowed(activeUser, 'read', 'transactions') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BILLING.INVOICES'
              ) ? (
                <InvoiceDetailsRevamped />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/manage">
              {operationAllowed(activeUser, 'write', 'organizations') ||
              operationAllowed(activeUser, 'write', 'accounts') ? (
                <Manage />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/crm">
              {hasSubscription(activeOrganization, 'feature-review') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'REVIEW.REVIEWS') ? (
                <ReviewSearch />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/crm/reviewEmbed">
              {hasSubscription(activeOrganization, 'feature-review') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'REVIEW.EMBED_CODE'
              ) ? (
                <ReviewEmbed />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/crm/settings">
              {hasSubscription(activeOrganization, 'feature-review') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'REVIEW.SETTINGS'
              ) ? (
                <ReviewSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/waivers/templates/:id/edit">
              {hasSubscription(activeOrganization, 'feature-digital-waiver') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'WAIVER.TEMPLATES'
              ) ? (
                <WaiverTemplateEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/waivers/templates/new">
              {hasSubscription(activeOrganization, 'feature-digital-waiver') &&
              tutorialCompleted &&
              hasCustomUserRoleWritePermissions(
                activeUser,
                'WAIVER.TEMPLATES'
              ) ? (
                <WaiverTemplateEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/waivers/settings">
              {hasSubscription(activeOrganization, 'feature-digital-waiver') &&
              tutorialCompleted &&
              hasCustomUserRoleWritePermissions(
                activeUser,
                'WAIVER.SETTINGS'
              ) ? (
                <WaiverSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/waivers/templates">
              {hasSubscription(activeOrganization, 'feature-digital-waiver') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'WAIVER.TEMPLATES'
              ) ? (
                <WaiverTemplateList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/waivers">
              {hasSubscription(activeOrganization, 'feature-digital-waiver') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'WAIVER.WAIVERS') ? (
                <WaiverList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/guides/schedules">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-guide-account') ? (
                <GuideSchedule />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/guides">
              {hasSubscription(activeOrganization, 'feature-guide-account') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'SHIFT_MANAGEMENT.STAFF'
              ) ? (
                <GuideList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/schedules">
              {hasSubscription(activeOrganization, 'feature-guide-account') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'SHIFT_MANAGEMENT.SCHEDULE'
              ) ? (
                <Schedule />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/homepages/about">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.HOMEPAGES'
              ) ? (
                <AboutUsEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/homepages/faq">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.HOMEPAGES'
              ) ? (
                <FaqEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/homepages/covid19">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.HOMEPAGES'
              ) ? (
                <Covid19Editor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/homepages/customPage">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.HOMEPAGES'
              ) ? (
                <CustomPages />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Settings V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/settings-v3">
                <SettingsV3 />
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/settings">
              <Settings />
            </PrivateRoute>
            <PrivateRoute exact path="/swagger-ui">
              <SwaggerUI />
            </PrivateRoute>
            <PrivateRoute hideMenu hideFrame exact path="/signup/defaults">
              <SignUpDefaults />
            </PrivateRoute>
            {/* Access Report V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/bookingWidget/reports-v3">
                {tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'BOOKING_SITE.ACCESS_REPORTS'
                ) ? (
                  <AccessReportV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/bookingWidget/reports">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.ACCESS_REPORTS'
              ) ? (
                <AccessReport />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Essential Pages V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/bookingWidget/homepages-v3">
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'BOOKING_SITE.HOMEPAGES'
                ) ? (
                  <EssentialPagesV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/bookingWidget/homepages">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.HOMEPAGES'
              ) ? (
                <EssentialPages />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Edit Custom Pages V3 */}
            {config.enableUIRevamp && (
              <PrivateRoute
                exact
                path="/bookingWidget/homepages-v3/editcustompage"
              >
                {operationAllowed(activeUser, 'write', 'productContents') &&
                tutorialCompleted &&
                hasCustomUserRoleReadPermissions(
                  activeUser,
                  'BOOKING_SITE.HOMEPAGES'
                ) ? (
                  <EditCustomPageV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/bookingWidget/homepages/editcustompage">
              {operationAllowed(activeUser, 'write', 'productContents') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'BOOKING_SITE.HOMEPAGES'
              ) ? (
                <EditCustomPage />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mediadownload/salespages">
              {hasSubscription(activeOrganization, 'feature-image-download') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MEDIA_DOWNLOAD.UPLOAD'
              ) ? (
                <MediaDownloadSalesPageList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mediadownload/prices">
              {hasSubscription(activeOrganization, 'feature-image-download') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MEDIA_DOWNLOAD.PRICES'
              ) ? (
                <MediaDownloadPriceList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mediadownload/settings">
              {hasSubscription(activeOrganization, 'feature-image-download') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MEDIA_DOWNLOAD.SETTINGS'
              ) ? (
                <MediaDownloadSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/mediadownload/orders">
              {hasSubscription(activeOrganization, 'feature-image-download') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MEDIA_DOWNLOAD.ORDERS'
              ) ? (
                <MediaDownloadOrderList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/digitalguidance/pages">
              {hasSubscription(
                activeOrganization,
                'feature-digital-guidance'
              ) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'DIGITAL_GUIDANCE.PAGES'
              ) ? (
                <DigitalGuidancePageList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/digitalguidance/coupons">
              {hasSubscription(
                activeOrganization,
                'feature-digital-guidance'
              ) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'DIGITAL_GUIDANCE.COUPONS'
              ) ? (
                <DigitalGuidanceCouponList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/digitalguidance/locations">
              {hasSubscription(
                activeOrganization,
                'feature-digital-guidance'
              ) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'DIGITAL_GUIDANCE'
              ) ? (
                <DigitalGuidanceLocationList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/digitalguidance/settings">
              {hasSubscription(
                activeOrganization,
                'feature-digital-guidance'
              ) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'DIGITAL_GUIDANCE.SETTINGS'
              ) ? (
                <DigitalGuidanceSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/digitalguidance/records">
              {hasSubscription(
                activeOrganization,
                'feature-digital-guidance'
              ) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'DIGITAL_GUIDANCE.REDEEMED_RECORDS'
              ) ? (
                <RedeemedRecordList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/digitalguidance/stamprallies">
              {hasSubscription(
                activeOrganization,
                'feature-digital-guidance'
              ) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'DIGITAL_GUIDANCE.STAMP_RALLIES'
              ) ? (
                <DigitalGuidanceStampRallyList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/eticket/checkinrecords">
              {hasSubscription(activeOrganization, 'feature-e-ticket') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'E_TICKET.REDEMPTIONS'
              ) ? (
                <CheckinRecordList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/eticket/locations">
              {hasSubscription(activeOrganization, 'feature-e-ticket') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'E_TICKET.LOCATIONS'
              ) ? (
                <ETicketLocationList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/eticket/footprints">
              {hasSubscription(activeOrganization, 'feature-e-ticket') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'E_TICKET.VIEW_HISTORY'
              ) ? (
                <FootprintList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/eticket/qrcodes">
              {hasSubscription(activeOrganization, 'feature-e-ticket') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'E_TICKET.QR_CODES'
              ) ? (
                <ETicketQrCodeList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/eticket/dailyuses">
              {hasSubscription(activeOrganization, 'feature-e-ticket') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'E_TICKET.DAILY_USES'
              ) ? (
                <ETicketDailyUses />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/eticket/redemptioncountdailyuses">
              {hasSubscription(activeOrganization, 'feature-e-ticket') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'E_TICKET.DAILY_USES'
              ) ? (
                <ETicketRedemptionCountDailyUses />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/inquiries">
              {hasSubscription(activeOrganization, 'feature-inquiry') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'INQUIRY.LIST') ? (
                <InquiryList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/inquiries/settings">
              {hasSubscription(activeOrganization, 'feature-inquiry') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'INQUIRY.SETTINGS'
              ) ? (
                <InquirySettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/inquiries/bulkemail">
              {hasSubscription(activeOrganization, 'feature-inquiry') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'INQUIRY.BULK_EMAIL'
              ) ? (
                <BulkEmailNotification />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/inquiries/bulksms">
              {hasSubscription(activeOrganization, 'feature-inquiry') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'INQUIRY.BULK_SMS'
              ) ? (
                <BulkSMSNotification />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/inquiries/smsbilling">
              {hasSubscription(activeOrganization, 'feature-inquiry') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'INQUIRY.SMS_USAGE'
              ) ? (
                <SmsBilling />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/inquiries/:id">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'INQUIRY.LIST') ? (
                <InquiryDetails />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* Don't check subscription or printable page because we load it in a new window and
               subscriptions might not be available on first load in the new window
            */}
            <PrivateRoute hideMenu hideFrame exact path="/inquiries/:id/print">
              <InquiryDetails printMode />
            </PrivateRoute>
            <PrivateRoute exact path="/surveys/submitted">
              {hasSubscription(activeOrganization, 'feature-survey') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'SURVEY.SUBMISSIONS'
              ) ? (
                <SubmittedSurveyList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/surveys/templates/:id/edit">
              {hasSubscription(activeOrganization, 'feature-survey') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SURVEYS') ? (
                <SurveyTemplateEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/surveys/templates/:id/results">
              {hasSubscription(activeOrganization, 'feature-survey') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SURVEYS') ? (
                <SurveyTemplateResults />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/surveys/templates/new">
              {hasSubscription(activeOrganization, 'feature-survey') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SURVEYS') ? (
                <SurveyTemplateEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/surveys/templates">
              {hasSubscription(activeOrganization, 'feature-survey') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SURVEYS') ? (
                <SurveyTemplateList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/surveys/settings">
              {hasSubscription(activeOrganization, 'feature-survey') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'SURVEY.SETTINGS'
              ) ? (
                <SurveySettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/surveys/dashboard">
              {hasSubscription(activeOrganization, 'feature-survey') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'SURVEY.SURVEYS') ? (
                <SurveyDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/customers/dashboard">
              {hasSubscription(activeOrganization, 'feature-customer-ledger') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CUSTOMER_LEDGER.LIST'
              ) ? (
                <CrmDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/customers/settings">
              {hasSubscription(activeOrganization, 'feature-customer-ledger') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CUSTOMER_LEDGER.SETTINGS'
              ) ? (
                <CustomerSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/customers">
              {hasSubscription(activeOrganization, 'feature-customer-ledger') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CUSTOMER_LEDGER.LIST'
              ) ? (
                <CustomerList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/customers/:id">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CUSTOMER_LEDGER.LIST'
              ) ? (
                <CustomerDetails />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats">
              {tutorialCompleted ? (
                <SeatAssignment />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/resources">
              {tutorialCompleted ? (
                <EquipmentList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/resourceschedules">
              {tutorialCompleted ? (
                <EquipmentScheduleList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/resourceschedules/:id">
              {tutorialCompleted ? (
                <EquipmentScheduleEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/resourceeditor">
              {tutorialCompleted ? (
                <EquipmentEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/editor">
              {tutorialCompleted ? (
                <SeatEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/bookingsourcegroups">
              {tutorialCompleted ? (
                <SeatManagementBookingSourceGroups />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/pricing">
              {tutorialCompleted ? (
                <SeatManagementPricing />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/weekly">
              {tutorialCompleted ? (
                <WeeklySeatAvailability />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/monthly">
              {tutorialCompleted ? (
                <MonthlySeatAvailability />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/seats/settings">
              {tutorialCompleted ? (
                <SeatManagementSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/newsletters/new">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-customer-ledger') ? (
                <SendNewsletterWizard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/newsletters/segments">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-customer-ledger') ? (
                <NewsletterSegmentList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/newsletters">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-customer-ledger') ? (
                <NewsletterList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/newsletters/:id/edit">
              {tutorialCompleted ? (
                <SendNewsletterWizard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/newsletters/:id/report">
              {tutorialCompleted ? (
                <NewsletterReport />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/newsletters/:id/email">
              {tutorialCompleted ? (
                <NewsletterEmail />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/newsletters/segments/:id/edit">
              {tutorialCompleted ? (
                <NewsletterSegmentEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/newsletters/segments/new">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-customer-ledger') ? (
                <NewsletterSegmentEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/accounts">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(activeUser, 'ACCOUNT.LIST') ? (
                <AccountList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* System Fee Reservations V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/systemfee/reservations-v3">
                {tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'SYSTEM_FEE.WEB_BOOKINGS'
                ) ? (
                  <>
                    {activeOrganization?.activity_plan_settings
                      ?.is_subscribed ? (
                      <ActivityPlanSystemFeeReservationsV3 />
                    ) : (
                      <SystemFeeReservationsV3 />
                    )}
                  </>
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/systemfee/reservations">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'SYSTEM_FEE.WEB_BOOKINGS'
              ) ? (
                <SystemFeeReservations />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* System Fee invoice details V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/systemfee/reservations-v3/:id">
                {tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'SYSTEM_FEE.WEB_BOOKINGS'
                ) ? (
                  <>
                    {activeOrganization?.activity_plan_settings
                      ?.is_subscribed ? (
                      <ActivityPlanSystemFeeInvoiceDetailsV3 />
                    ) : (
                      <SystemFeeInvoiceDetailsV3 />
                    )}
                  </>
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/systemfee/reservations/:id">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'SYSTEM_FEE.WEB_BOOKINGS'
              ) ? (
                <SystemFeeInvoiceDetails />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* System Fee Subscriptions V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/systemfee/subscriptions-v3">
                {tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'SYSTEM_FEE.SUBSCRIPTIONS'
                ) ? (
                  <SystemFeeSubscriptionsV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/systemfee/subscriptions">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'SYSTEM_FEE.SUBSCRIPTIONS'
              ) ? (
                <SystemFeeSubscriptions />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            {/* System Fee Settings V3 */}
            {(config.enableUIRevamp ||
              config.enableUIRevampForDemo ||
              config.enableUIRevampForRelease) && (
              <PrivateRoute exact path="/systemfee/settings-v3">
                {tutorialCompleted &&
                hasCustomUserRoleWritePermissions(
                  activeUser,
                  'SYSTEM_FEE.SETTINGS'
                ) ? (
                  <SystemFeeSettingsV3 />
                ) : (
                  <Redirect to={homeRoute(activeUser, activeOrganization)} />
                )}
              </PrivateRoute>
            )}
            <PrivateRoute exact path="/systemfee/settings">
              {tutorialCompleted &&
              hasCustomUserRoleWritePermissions(
                activeUser,
                'SYSTEM_FEE.SETTINGS'
              ) ? (
                <SystemFeeSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/notifications/settings">
              {tutorialCompleted ? (
                <NotificationSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/notifications/templates">
              {tutorialCompleted ? (
                <NotificationTemplateList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/notifications/templates/:id/edit">
              {tutorialCompleted ? (
                <NotificationTemplateEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/notifications/templates/new">
              {tutorialCompleted ? (
                <NotificationTemplateEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/offers">
              {tutorialCompleted ? (
                <SalesOfferList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/offers/stats">
              {tutorialCompleted ? (
                <SalesOfferStats />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/offers/:id/edit">
              {tutorialCompleted ? (
                <SalesOfferEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/offers/new">
              {tutorialCompleted ? (
                <SalesOfferEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/instantwins">
              {tutorialCompleted ? (
                <InstantWinList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/instantwins/events">
              {tutorialCompleted ? (
                <InstantWinEventList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/instantwins/settings">
              {tutorialCompleted ? (
                <InstantWinSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/channelmanager">
              {config.enableChannelManager &&
              hasSubscription(activeOrganization, 'feature-channel-manager') &&
              commissionsTableEnabledForAccount(activeUser) &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CHANNEL_MANAGER.COMMISSIONS'
              ) ? (
                <ProductPriceList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/channelmanager/jaranimport">
              {config.enableChannelManager &&
              hasSubscription(activeOrganization, 'feature-channel-manager') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CHANNEL_MANAGER.JARAN_IMPORT'
              ) ? (
                <JaranImport />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/channelmanager/billing">
              {config.enableChannelManager &&
              hasSubscription(activeOrganization, 'feature-channel-manager') &&
              operationAllowed(activeUser, 'read', 'transactions') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CHANNEL_MANAGER.INVOICES'
              ) ? (
                <InvoiceRevamped />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/channelmanager/billing/period">
              {config.enableChannelManager &&
              hasSubscription(activeOrganization, 'feature-channel-manager') &&
              operationAllowed(activeUser, 'write', 'billingPeriod') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CHANNEL_MANAGER.PERIODS'
              ) ? (
                <BillingPeriodRevamped />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/channelmanager/billing/:id">
              {config.enableChannelManager &&
              hasSubscription(activeOrganization, 'feature-channel-manager') &&
              operationAllowed(activeUser, 'read', 'transactions') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'CHANNEL_MANAGER.INVOICES'
              ) ? (
                <InvoiceDetailsRevamped />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/groups">
              {hasSubscription(
                activeOrganization,
                'feature-group-ticketing'
              ) ? (
                <GroupBookingTemplateList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/groups/:id/edit">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-group-ticketing') ? (
                <GroupBookingTemplateEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/groups/:id/reservations">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-group-ticketing') ? (
                <GroupReservationList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/groups/new">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-group-ticketing') ? (
                <GroupBookingTemplateEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <DigitalMapList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/dashboard">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <DigitalMapDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/guestjourneys">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <DigitalMapGuestJourneyList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/:mapId/guestjourneys/:journeyId">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <DigitalMapGuestJourneyDetails />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/:id/edit">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <DigitalMapEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/new">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <DigitalMapEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/reports">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <DigitalMapAccessReport />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/waittimes">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <WaitTimeList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/busroutes">
              {tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'MAPS.BUS_ROUTES'
              ) ? (
                <BusRouteList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/chatdatasource">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <ChatDataSourceSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute
              hideMenu
              hideFrame
              exact
              path="/maps/waittimesmanager"
            >
              {tutorialCompleted ? (
                <WaitTimeManager />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/coupons">
              {hasSubscription(activeOrganization, 'feature-digital-map') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'DIGITAL_GUIDANCE.COUPONS'
              ) ? (
                <DigitalGuidanceCouponList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/stamprallies">
              {hasSubscription(activeOrganization, 'feature-digital-map') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'DIGITAL_GUIDANCE.STAMP_RALLIES'
              ) ? (
                <DigitalGuidanceStampRallyList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/journeyanalytics/customers">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-journey-analytics'
              ) ? (
                <JourneyAnalyticsCustomerList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/journeyanalytics/conversionpaths">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-journey-analytics'
              ) ? (
                <JourneyAnalyticsConversionPath />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/journeyanalytics/customers/:id">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-journey-analytics'
              ) ? (
                <JourneyAnalyticsCustomerDetails />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/journeyanalytics/dashboard">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-journey-analytics'
              ) ? (
                <JourneyAnalyticsDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/journeyanalytics/settings">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-journey-analytics'
              ) ? (
                <JourneyAnalyticsSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/journeyanalytics/privacypolicies">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-journey-analytics'
              ) ? (
                <JourneyAnalyticsPrivacyPolicies />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/journeyanalytics/embed">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-journey-analytics'
              ) ? (
                <JourneyAnalyticsEmbedCode />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/onsitejourneyanalytics/customers">
              {tutorialCompleted &&
              isOnSiteJourneyAnalyticsSupplier(
                activeOrganization,
                loggedInAccountIsNutmegAdmin
              ) ? (
                <OnSiteJourneyAnalyticsCustomerList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/onsitejourneyanalytics/customers/:id">
              {tutorialCompleted &&
              isOnSiteJourneyAnalyticsSupplier(
                activeOrganization,
                loggedInAccountIsNutmegAdmin
              ) ? (
                <OnSiteJourneyAnalyticsCustomerDetails />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/onsitejourneyanalytics/dashboard">
              {tutorialCompleted &&
              isOnSiteJourneyAnalyticsSupplier(
                activeOrganization,
                loggedInAccountIsNutmegAdmin
              ) ? (
                <OnSiteJourneyAnalyticsDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/onsitejourneyanalytics/settings">
              {tutorialCompleted &&
              isOnSiteJourneyAnalyticsSupplier(
                activeOrganization,
                loggedInAccountIsNutmegAdmin
              ) ? (
                <JourneyAnalyticsSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/dashboard">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/segments">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationSegmentList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/segments/:id/edit">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationSegmentEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/segments/new">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationSegmentEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/triggers">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationTriggerConditionList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/triggers/new">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationTriggerConditionEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/triggers/:id/edit">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationTriggerConditionEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/contents">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationContentList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/contents/new">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationContentEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/contents/:id/edit">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationContentEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/campaigns">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationCampaignList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/campaigns/new">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationCampaignEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/campaigns/:id/edit">
              {tutorialCompleted &&
              hasSubscription(
                activeOrganization,
                'feature-marketing-automation'
              ) ? (
                <MarketingAutomationCampaignEditor />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/marketingautomation/campaigns/:id/report"
            >
              {tutorialCompleted ? (
                <MarketingAutomationCampaignReport />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/marketingautomation/campaigns/:id/email">
              {tutorialCompleted ? (
                <MarketingAutomationCampaignEmail />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>

            <PrivateRoute exact path="/partnership/reservations">
              {tutorialCompleted ? (
                <PartnershipReservationSearch />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>

            <PrivateRoute exact path="/partnership/reservations/:id">
              {tutorialCompleted ? (
                <PartnershipReservation />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/partnership/supplierproducts">
              {tutorialCompleted ? (
                <PartnershipSupplierProducts />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/partnership/agentsystemfeeinvoices">
              {tutorialCompleted ? (
                <PartnershipAgentSystemFeeInvoices />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/partnership/agentsystemfeelineitems">
              {tutorialCompleted ? (
                <PartnershipAgentSystemFeeLineItems />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/partnership/checkinrecords">
              {tutorialCompleted ? (
                <PartnershipCheckinRecordList />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/partnership/packageproducts">
              {tutorialCompleted ? (
                <PartnershipPackageProducts />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/partnership/suppliersystemfeeinvoices">
              {tutorialCompleted ? (
                <PartnershipSystemFeeReservations />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/partnership/suppliersystemfeeinvoices/:id"
            >
              {tutorialCompleted ? (
                <PartnershipSystemFeeInvoiceDetails />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/partnership/products/:productID/instances/:instanceID/book-v3"
            >
              {tutorialCompleted &&
              operationAllowed(activeUser, 'write', 'reservations') ? (
                <PartnershipReservationBooking />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>

            <PrivateRoute exact path="/annualpasses">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.LIST'
              ) ? (
                <AnnualPassSearch />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/annualpasses/dashboard">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.LIST'
              ) ? (
                <AnnualPassDashboard />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/annualpasses/expiredtimereport">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.LIST'
              ) ? (
                <ExpiredTimeReport />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/annualpasses/reservations">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.LIST'
              ) ? (
                <ETicketFootprintDateCount annualPassOnly={true} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/annualpasses/reservations/:id">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.LIST'
              ) ? (
                <ETicketFootprintReservations annualPassOnly={true} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/annualpasses/offers">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              tutorialCompleted ? (
                <SalesOfferList annualPassMode={true} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/annualpasses/offers/:id/edit">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              tutorialCompleted ? (
                <SalesOfferEditor annualPassMode={true} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/annualpasses/offers/new">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              tutorialCompleted ? (
                <SalesOfferEditor annualPassMode={true} />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/annualpasses/:id">
              {hasSubscription(activeOrganization, 'feature-annual-pass') &&
              operationAllowed(activeUser, 'read', 'reservations') &&
              tutorialCompleted &&
              hasCustomUserRoleReadPermissions(
                activeUser,
                'RESERVATION.LIST'
              ) ? (
                <Reservation />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <PrivateRoute exact path="/maps/settings">
              {tutorialCompleted &&
              hasSubscription(activeOrganization, 'feature-digital-map') ? (
                <DigitalMapSettings />
              ) : (
                <Redirect to={homeRoute(activeUser, activeOrganization)} />
              )}
            </PrivateRoute>
            <Route component={Oops} />
          </Switch>
        </WebSocketConnectionProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
