import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ProductGroupList } from 'client/pages/v3/Availability/ProductGroup/ProductGroupList/ProductGroupList';
import { AvailabilityProductGroup } from 'client/pages/v3/Availability/ProductGroup/AvailabilityProductGroup/AvailabilityProductGroup';
import baseStyles from 'client/v3-base.module.css';
import { AvailabilityHeader } from 'client/pages/v3/Availability/Shared/AvailabilityHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { PageContent } from 'client/components/v3/Page/PageContent';

import styles from './ProductGroup.module.css';

type TabType = 'CALENDAR' | 'SETTING';

export const ProductGroup = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabType>('CALENDAR');

  return (
    <V3Page>
      <AvailabilityHeader title={t('Product Group')} />
      <PageContent>
        <div className={baseStyles['l-main__body']}>
          <section
            className={clsx(baseStyles['g-section'], baseStyles['u-mb-4'])}
          >
            <div className={styles['p-manifests__customize']}>
              <div className={styles['p-manifests__customize__header']}>
                <ul className={styles['p-manifests__customize__header__tab']}>
                  <li
                    className={clsx(
                      activeTab === 'CALENDAR' && styles['is-active']
                    )}
                    onClick={() => {
                      setActiveTab('CALENDAR');
                    }}
                  >
                    {t('Calendar')}
                  </li>
                  <li
                    className={clsx(
                      activeTab === 'SETTING' && styles['is-active']
                    )}
                    onClick={() => {
                      setActiveTab('SETTING');
                    }}
                  >
                    {t('Product Group Setting')}
                  </li>
                </ul>
              </div>
              {activeTab === 'CALENDAR' && <AvailabilityProductGroup />}
              {activeTab === 'SETTING' && (
                <div className={styles['p-manifests__customize__body']}>
                  <ProductGroupList />
                </div>
              )}
            </div>
          </section>
        </div>
      </PageContent>
    </V3Page>
  );
};
