import * as React from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useForm, useFormState } from 'react-final-form';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { TimePicker } from 'client/components/v3/Form/TimePicker/TimePicker';

import { FormValues } from './FormValues';
import styles from './StartTimeAdder.module.css';

interface Props {
  name: string;
}

export const StartTimeAdder = ({ name }: Props) => {
  const { t } = useTranslation();
  const [newStartTime, setNewStartTime] = React.useState<string>('09:00');

  const { values } = useFormState<FormValues>();
  const form = useForm();

  const schedule = _.get(values, name);
  const addNewSchedule = () => {
    form.change(name, {
      ...schedule,
      startTimeMappings: [
        ...schedule.startTimeMappings,
        {
          key: uuidv4(),
          startTimeLocal: newStartTime,
        },
      ],
    });
  };

  return (
    <div>
      <div
        className={styles['p-equipmentScheduleEditor__startTimeAdder__select']}
      >
        <div
          className={
            styles['p-equipmentScheduleEditor__startTimeAdder__select__box']
          }
        >
          <TimePicker
            value={moment(newStartTime, 'HH:mm')}
            onChange={(value) =>
              setNewStartTime(value ? value.format('HH:mm') : '09:00')
            }
          />
          <a
            className={styles['p-equipemntScheduleEditor__startTimeAdder__add']}
            onClick={addNewSchedule}
          >
            <i className="c-icon-outline-general-plus-circle"></i>
            {t('Add Start Time')}
          </a>
        </div>
      </div>
    </div>
  );
};
