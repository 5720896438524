import { Field } from 'react-final-form';

import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';

import styles from './Schedule.module.css';

interface Props {
  name: string;
}

export const RangeEditor = ({ name }: Props) => {
  return (
    <div className={styles['p-list__item__body__calendar']}>
      <>
        <Field name={`${name}.startDateLocal`}>
          {({ input }) => (
            <SimpleDateInput
              onChange={input.onChange}
              dateFrom={input.value}
              name={input.name}
            />
          )}
        </Field>
        <p>-</p>
        <Field name={`${name}.endDateLocal`}>
          {({ input }) => (
            <SimpleDateInput
              onChange={input.onChange}
              dateFrom={input.value}
              name={input.name}
            />
          )}
        </Field>
      </>
    </div>
  );
};
