export interface FeatureFlags {
  enableNtaProductSettings: boolean;
  enableGoogleMyBusinessFeature: boolean;
  enableBookInDigitalGuidance: boolean;
  enableProductMinimumPrice: boolean;
  enableResourceManagementVehicle: boolean;
  enableResourceManagementCalendar: boolean;
  enableCrossSellUpSell: boolean;
  enableInstantWin: boolean;
  enableChannelManager: boolean;
  enableNotifications: boolean;
  enableShowFieldResponsesOnFootprintedModal: boolean;
  enableUIRevamp: boolean;
  enableWaiverListEnhancement: boolean;
  enableETicketBarCode: boolean;
  enablePMPCategoryHideSettings: boolean;
  enableImportedCustomerListSegment: boolean;
  enableHPFeatureImprovements: boolean;
  enableCreateReservationButtonForPassthroughSupplier: boolean;
  enableBatchEditEquipmentInstanceAvailability: boolean;
  enablePartnership: boolean;
  enableUndoEqauipmentTicketPrint: boolean;
  enableCustomerLedgerAddressInputForm: boolean;
  enableEmailCustomizationForLotteryMode: boolean;
  enableUIRevampForDemo: boolean;
  enableMemberLimitedProduct: boolean;
  enableUIRevampForRelease: boolean;
  enableMaRevamp: boolean;
  enableAnnualPassDashboard: boolean;
  enableNewsletterNewDesign: boolean;
  enableConsolidatedMarketingAutomationContent: boolean;
  enablePickupLocation: boolean;
  enableMobileOrder: boolean;
  enableNewsletterCustomEditor: boolean;
  enableOnSiteJourneyAnalytics: boolean;
  enableLineItemTable: boolean;
  enableNpsSurvey: boolean;
  enablePartnershipPackageProduct: boolean;
  enablePartnershipSystemFeeInvoiceTable: boolean;
  enableDynamicPackageProduct: boolean;
  enableMapCachedLinks: boolean;
  enableDigitalMapGuestJourneys: boolean;
  enableConversionPath: boolean;
  enableMultifloorMaps: boolean;
  enableCustomMapHeaderMenuSettings: boolean;
  enableMapLanguageMenuSettings: boolean;
  enableMapOpenTableSettings: boolean;
  enableMapUberButtonSettings: boolean;
  enableMapQRCodeButtonSettings: boolean;
  enableMapHighResolutionTileSettings: boolean;
  enableCognitoCacheJwks: boolean;
  enableChatbot: boolean;
  enableAutomaticWaitTimeSettings: boolean;
  enableWaitTimeHistory: boolean;
  enableNoIndexCustomPage: boolean;
  enableItineraryMaker: boolean;
  enableMapBusRoutes: boolean;
  enableMapCustomGoogleMapId: boolean;
  enableGroupBookingInvoice: boolean;
  enableDigitalMapBusRoutes: boolean;
  enableDigitalMapPwa: boolean;
  enableNotationOfMeetingTime: boolean;
  enableETicketFieldResponse: boolean;
  enableActivityPlan: boolean;
  enableMonthlyPass: boolean;
  enableChatbotInternal: boolean;
  enableEquipmentScheduleList: boolean;
  enableProductGroup: boolean;
}

// In "development" mode, all features under development are enabled.
export const developmentFeatureFlags: FeatureFlags = {
  enableNtaProductSettings: true,
  enableGoogleMyBusinessFeature: true,
  enableBookInDigitalGuidance: true,
  enableProductMinimumPrice: true,
  enableResourceManagementVehicle: true,
  enableResourceManagementCalendar: true,
  enableCrossSellUpSell: true,
  enableInstantWin: true,
  enableChannelManager: true,
  enableNotifications: true,
  enableShowFieldResponsesOnFootprintedModal: true,
  enableUIRevamp: true,
  enableWaiverListEnhancement: true,
  enableETicketBarCode: true,
  enablePMPCategoryHideSettings: true,
  enableImportedCustomerListSegment: true,
  enableHPFeatureImprovements: false,
  enableCreateReservationButtonForPassthroughSupplier: true,
  enableBatchEditEquipmentInstanceAvailability: true,
  enablePartnership: true,
  enableUndoEqauipmentTicketPrint: true,
  enableCustomerLedgerAddressInputForm: true,
  enableEmailCustomizationForLotteryMode: true,
  enableUIRevampForDemo: true,
  enableMemberLimitedProduct: true,
  enableUIRevampForRelease: true,
  enableMaRevamp: true,
  enableAnnualPassDashboard: true,
  enableNewsletterNewDesign: true,
  enableConsolidatedMarketingAutomationContent: true,
  enablePickupLocation: true,
  enableMobileOrder: true,
  enableNewsletterCustomEditor: true,
  enableOnSiteJourneyAnalytics: true,
  enableLineItemTable: true,
  enableNpsSurvey: true,
  enablePartnershipPackageProduct: true,
  enablePartnershipSystemFeeInvoiceTable: true,
  enableDynamicPackageProduct: true,
  enableMapCachedLinks: true,
  enableDigitalMapGuestJourneys: true,
  enableConversionPath: true,
  enableMultifloorMaps: true,
  enableCustomMapHeaderMenuSettings: true,
  enableMapLanguageMenuSettings: true,
  enableMapOpenTableSettings: true,
  enableMapUberButtonSettings: true,
  enableMapQRCodeButtonSettings: true,
  enableMapHighResolutionTileSettings: true,
  enableCognitoCacheJwks: true,
  enableChatbot: true,
  enableAutomaticWaitTimeSettings: true,
  enableWaitTimeHistory: true,
  enableNoIndexCustomPage: true,
  enableItineraryMaker: true,
  enableMapBusRoutes: true,
  enableMapCustomGoogleMapId: true,
  enableGroupBookingInvoice: true,
  enableDigitalMapBusRoutes: true,
  enableDigitalMapPwa: true,
  enableNotationOfMeetingTime: true,
  enableETicketFieldResponse: true,
  enableActivityPlan: true,
  enableMonthlyPass: true,
  enableChatbotInternal: true,
  enableEquipmentScheduleList: true,
  enableProductGroup: true,
};

// In "release" mode, only features ready for production release are enabled.
export const releaseFeatureFlags: FeatureFlags = {
  enableNtaProductSettings: false,
  enableGoogleMyBusinessFeature: false,
  enableBookInDigitalGuidance: true,
  enableProductMinimumPrice: false,
  enableResourceManagementVehicle: false,
  enableResourceManagementCalendar: false,
  enableCrossSellUpSell: false,
  enableInstantWin: false,
  enableChannelManager: true,
  enableNotifications: false,
  enableShowFieldResponsesOnFootprintedModal: true,
  enableUIRevamp: false,
  enableWaiverListEnhancement: true,
  enableETicketBarCode: false,
  enablePMPCategoryHideSettings: true,
  enableImportedCustomerListSegment: true,
  enableHPFeatureImprovements: false,
  enableCreateReservationButtonForPassthroughSupplier: true,
  enableBatchEditEquipmentInstanceAvailability: true,
  enablePartnership: true,
  enableUndoEqauipmentTicketPrint: true,
  enableCustomerLedgerAddressInputForm: true,
  enableEmailCustomizationForLotteryMode: true,
  enableUIRevampForDemo: false,
  enableMemberLimitedProduct: false,
  enableUIRevampForRelease: true,
  enableMaRevamp: false,
  enableAnnualPassDashboard: true,
  enableNewsletterNewDesign: true,
  enableConsolidatedMarketingAutomationContent: false,
  enablePickupLocation: true,
  enableMobileOrder: false,
  enableNewsletterCustomEditor: true,
  enableOnSiteJourneyAnalytics: true,
  enableLineItemTable: true,
  enableNpsSurvey: true,
  enablePartnershipPackageProduct: true,
  enablePartnershipSystemFeeInvoiceTable: true,
  enableDynamicPackageProduct: true,
  enableMapCachedLinks: false,
  enableDigitalMapGuestJourneys: false,
  enableConversionPath: false,
  enableMultifloorMaps: false,
  enableCustomMapHeaderMenuSettings: true,
  enableMapLanguageMenuSettings: false,
  enableMapOpenTableSettings: false,
  enableMapUberButtonSettings: false,
  enableMapQRCodeButtonSettings: false,
  enableMapHighResolutionTileSettings: false,
  enableCognitoCacheJwks: false,
  enableChatbot: false,
  enableAutomaticWaitTimeSettings: false,
  enableWaitTimeHistory: false,
  enableNoIndexCustomPage: true,
  enableItineraryMaker: false,
  enableMapBusRoutes: false,
  enableMapCustomGoogleMapId: false,
  enableGroupBookingInvoice: true,
  enableDigitalMapBusRoutes: false,
  enableDigitalMapPwa: false,
  enableNotationOfMeetingTime: true,
  enableETicketFieldResponse: true,
  enableActivityPlan: true,
  enableMonthlyPass: false,
  enableChatbotInternal: false,
  enableEquipmentScheduleList: false,
  enableProductGroup: false,
};

// stg feature flags are not ready for release but are enabled for demo purposes in the stg environment.
// This breaks our "production is the same as staging" testing so it should be used sparingly.
export const stgDemoFeatureFlags: Partial<FeatureFlags> = {
  enableNtaProductSettings: true,
  enableBookInDigitalGuidance: true,
  enableShowFieldResponsesOnFootprintedModal: true,
  enableChannelManager: true,
  enableETicketBarCode: true,
  enableImportedCustomerListSegment: true,
  enablePMPCategoryHideSettings: true,
  enableBatchEditEquipmentInstanceAvailability: true,
  enableCreateReservationButtonForPassthroughSupplier: true,
  enablePartnership: true,
  enableUndoEqauipmentTicketPrint: true,
  enableCustomerLedgerAddressInputForm: true,
  enableEmailCustomizationForLotteryMode: true,
  enableUIRevampForDemo: true,
  enableMemberLimitedProduct: true,
  enableUIRevampForRelease: true,
  enableMaRevamp: true,
  enableInstantWin: true,
  enableAnnualPassDashboard: true,
  enablePickupLocation: true,
  enableMobileOrder: true,
  enableConsolidatedMarketingAutomationContent: true,
  enableLineItemTable: true,
  enableNpsSurvey: true,
  enablePartnershipPackageProduct: true,
  enablePartnershipSystemFeeInvoiceTable: true,
  enableDynamicPackageProduct: true,
  enableMapCachedLinks: true,
  enableDigitalMapGuestJourneys: true,
  enableMultifloorMaps: true,
  enableCustomMapHeaderMenuSettings: true,
  enableMapLanguageMenuSettings: true,
  enableMapOpenTableSettings: true,
  enableMapUberButtonSettings: true,
  enableMapQRCodeButtonSettings: true,
  enableMapHighResolutionTileSettings: true,
  enableChatbot: true,
  enableAutomaticWaitTimeSettings: true,
  enableWaitTimeHistory: true,
  enableItineraryMaker: true,
  enableMapBusRoutes: true,
  enableMapCustomGoogleMapId: true,
  enableGroupBookingInvoice: true,
  enableDigitalMapBusRoutes: true,
  enableDigitalMapPwa: true,
  enableETicketFieldResponse: true,
  enableActivityPlan: true,
  enableMonthlyPass: true,
  enableChatbotInternal: true,
  enableEquipmentScheduleList: true,
};
