import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import {
  setWeeklyAvailabilityStartDate,
  setAvailabilityStartDate,
  setAvailabilityEndDate,
} from 'client/actions/productCalendarListControls';
import { productCalendarListDatesSelector } from 'client/reducers/productCalendarListControls';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { activeUserSelector } from 'client/reducers/user';
import baseStyles from 'client/v3-base.module.css';
import { CalendarEditButtonPopup } from 'client/pages/v3/Availability/Shared/Popup/CalendarEditButtonPopup';
import { AvailabilityWrapper } from 'client/pages/v3/Availability/Shared/AvailabilityWrapper';
import { CalendarEditContext } from 'client/pages/v3/Availability/Shared/CalendarEditContext';
import { Button } from 'client/components/v3/Common/Button';
import { DateTimeTZInput } from 'client/components/v3/Form/Calendar/DateTimeTZInput';
//import { FilterPopup } from 'client/pages/v3/Availability/Shared/Popup/FilterPopup';
import { Tooltip } from 'client/components/v3/Common/Tooltip';

import commonStyles from './AvailabilityProductGroup.module.css';

export const AvailabilityProductGroup = () => {
  const [selectedProductInstanceIds, setSelectedProductInstanceIds] = useState<
    string[]
  >([]);
  const [calendarEditModeIsActive, setCalendarEditModeIsActive] =
    useState(false);

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const [activeView, setActiveView] = useState<'calendar' | 'table'>('table');

  const dates = useSelector(productCalendarListDatesSelector);
  const weeklyAvailabilityStartDate = useSelector(
    (state: ReduxState) =>
      state.productCalendarListControls.weeklyAvailabilityStartDate
  );
  const activeUser = useSelector(activeUserSelector);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      setAvailabilityStartDate(
        moment(weeklyAvailabilityStartDate).format('YYYY-MM-DD')
      )
    );
    dispatch(
      setAvailabilityEndDate(
        moment(weeklyAvailabilityStartDate).add(7, 'days').format('YYYY-MM-DD')
      )
    );
  }, [weeklyAvailabilityStartDate]);

  const handleViewChange = (view: 'calendar' | 'table') => {
    setActiveView(view);
  };

  return (
    <CalendarEditContext.Provider
      value={{
        selectedProductInstanceIds,
        selectProductInstanceIds: (...ids) => {
          const idsToAdd = ids.filter(
            (id) => !selectedProductInstanceIds.includes(id)
          );
          if (idsToAdd.length) {
            setSelectedProductInstanceIds([
              ...selectedProductInstanceIds,
              ...idsToAdd,
            ]);
          }
        },
        deselectProductInstanceIds: (...ids) => {
          setSelectedProductInstanceIds(
            selectedProductInstanceIds.filter((id) => !ids.includes(id))
          );
        },
        calendarEditModeIsActive,
        setCalendarEditModeIsActive,
      }}
    >
      <section className={baseStyles['g-section']}>
        <div
          className={clsx(
            commonStyles['p-availability'],
            calendarEditModeIsActive &&
              commonStyles['p-availability__fixedActive']
          )}
        >
          <div className={commonStyles['p-availability__header']}>
            <div className={commonStyles['p-availability__header__left']}>
              <div
                className={commonStyles['p-availability__header__left__date']}
              >
                <Button
                  size="icon"
                  color="white"
                  onClick={() => {
                    dispatch(
                      setWeeklyAvailabilityStartDate(
                        moment(weeklyAvailabilityStartDate)
                          .add(-7, 'days')
                          .format('YYYY-MM-DD')
                      )
                    );
                  }}
                  iconBeforeText={
                    <i className="c-icon-outline-arrows-chevron-left"></i>
                  }
                />
                <DateTimeTZInput
                  value={moment(dates[0])}
                  onChange={(date) => {
                    dispatch(
                      setWeeklyAvailabilityStartDate(
                        moment(date).format('YYYY-MM-DD')
                      )
                    );
                  }}
                  locale={locale}
                />
                <Button
                  size="icon"
                  color="white"
                  onClick={() => {
                    dispatch(
                      setWeeklyAvailabilityStartDate(
                        moment(weeklyAvailabilityStartDate)
                          .add(7, 'days')
                          .format('YYYY-MM-DD')
                      )
                    );
                  }}
                  iconBeforeText={
                    <i className="c-icon-outline-arrows-chevron-right"></i>
                  }
                />
              </div>
              <ul className={commonStyles['p-availability__header__left__tab']}>
                <Tooltip
                  text={t(
                    'The availability of the product is displayed in a table.'
                  )}
                >
                  <li
                    className={
                      activeView === 'table' ? commonStyles['is-active'] : ''
                    }
                    onClick={() => handleViewChange('table')}
                  >
                    <i className="c-icon-outline-layout-layout-alt-01"></i>
                  </li>
                </Tooltip>
                <Tooltip
                  text={t(
                    'The availability of the product is displayed in the calendar format. Please use filters to see the desired products’ availabilities.'
                  )}
                  width={360}
                >
                  <li
                    className={
                      activeView === 'calendar' ? commonStyles['is-active'] : ''
                    }
                    onClick={() => handleViewChange('calendar')}
                  >
                    <i className="c-icon-outline-time-calendar"></i>
                  </li>
                </Tooltip>
              </ul>
              {hasCustomUserRoleWritePermissions(
                activeUser,
                'AVAILABILITY.WEEKLY'
              ) && <CalendarEditButtonPopup view={activeView} />}
            </div>
          </div>

          <AvailabilityWrapper type="weekly" view={activeView} />
        </div>
      </section>
    </CalendarEditContext.Provider>
  );
};
