import { FieldArray } from 'react-final-form-arrays';
import { useFormState } from 'react-final-form';
import _ from 'lodash';

import { Schedule } from './Schedule';

interface Props {
  selectedScheduleKey: string | undefined;
}

export const ParticipationRule = ({ selectedScheduleKey }: Props) => {
  const { values } = useFormState();
  return (
    <div>
      <FieldArray name="schedules">
        {({ fields }) => (
          <>
            {fields.map((name, index) => {
              const schedule = _.get(values, name);
              if (schedule.key !== selectedScheduleKey) {
                return null;
              }

              return (
                <Schedule
                  selectedScheduleKey={selectedScheduleKey}
                  key={index}
                  name={name}
                />
              );
            })}
          </>
        )}
      </FieldArray>
    </div>
  );
};
