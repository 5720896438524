import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import styles from 'client/pages/v3/Availability/AvailabilityCommon.module.css';

type Props = {
  title: string;
};

export const AvailabilityHeader = ({ title }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const weeklyUrl = '/availability/weekly-v3';
  const monthlyUrl = '/availability/monthly-v3';
  const customRangeUrl = '/availability/custom';
  const productGroupUrl = '/availability/productGroup';

  return (
    <PageHeader title={title}>
      <ul className={styles['p-availabilityTab']}>
        <Link to={weeklyUrl}>
          <li
            className={
              location.pathname === weeklyUrl ? styles['is-active'] : ''
            }
          >
            {t('Weekly')}
          </li>
        </Link>
        <Link to={monthlyUrl}>
          <li
            className={
              location.pathname === monthlyUrl ? styles['is-active'] : ''
            }
          >
            {t('Monthly')}
          </li>
        </Link>
        <Link to={customRangeUrl}>
          <li
            className={
              location.pathname === customRangeUrl ? styles['is-active'] : ''
            }
          >
            {t('Custom')}
          </li>
        </Link>
        <Link to={productGroupUrl}>
          <li
            className={
              location.pathname === productGroupUrl ? styles['is-active'] : ''
            }
          >
            {t('Product Group')}
          </li>
        </Link>
      </ul>
    </PageHeader>
  );
};
