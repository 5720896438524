import {
  convertToRelativeDeadline,
  convertToSwaggerRelativeDateTime,
  RelativeDeadline,
} from 'client/libraries/util/productShape';
import { Weekday } from 'client/libraries/util/weekdays';
import {
  AutomaticAvailabilityAdjustmentConditionOperator,
  AutomaticAvailabilityAdjustmentValueType,
  Product,
  Product$Patch,
  AvailabilityChannelCategory,
} from 'shared/models/swagger';

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface FormValues {
  schedules: PriceAdjustmentSchedule[];
}

export interface PriceAdjustmentSchedule {
  participationDateRanges: DateRange[];
  participationDaysOfWeek: Weekday[];
  appliesFromParticipationRelativeDate?: RelativeDeadline;
  appliesUntilParticipationRelativeDate?: RelativeDeadline;
  rules: PriceAdjustmentRule[];
}

export interface PriceAdjustmentRule {
  conditions: PriceAdjustmentCondition[];
  action: PriceAdjustmentAction;
}

export interface PriceAdjustmentCondition {
  valueType: AutomaticAvailabilityAdjustmentValueType;
  operator: AutomaticAvailabilityAdjustmentConditionOperator;
  value: number;
  appliesTo: 'TOTAL' | 'CHANNEL';
  channel?: Channel;
}

export interface Channel {
  agentId: string;
  channelCategory: AvailabilityChannelCategory;
}

export interface PriceAdjustmentAction {
  percentOrFixed: 'PERCENT' | 'FIXED';
  channelType: 'ALL' | 'PER_CHANNEL';
  deltaType: 'CHARGE' | 'DISCOUNT';
  unitPriceDeltas: UnitPriceDelta[];
  channels: Channel[];
}

export interface UnitPriceDelta {
  method: 'PER_BOOKING' | 'PER_PARTICIPANT' | 'ANY';
  guestTypeKey: string;
  deltaFixedGross: string;
  deltaFixedNet: string;
  deltaPercent: number;
}

export const getInitialValues = (product: Product | null): FormValues => {
  return {
    schedules:
      product?.automatic_price_adjustment_settings?.schedules?.map(
        (schedule) => ({
          participationDateRanges:
            schedule.participation_date_ranges?.map((dateRange) => ({
              startDate: dateRange.start_date_local ?? '',
              endDate: dateRange.end_date_local ?? '',
            })) ?? [],
          participationDaysOfWeek:
            schedule.participation_days_of_week?.map((day) => day) ?? [],
          appliesFromParticipationRelativeDate:
            schedule.applies_from_participation_relative_date_time
              ? convertToRelativeDeadline(
                  schedule.applies_from_participation_relative_date_time
                )
              : undefined,
          appliesUntilParticipationRelativeDate:
            schedule.applies_until_participation_relative_date_time
              ? convertToRelativeDeadline(
                  schedule.applies_until_participation_relative_date_time
                )
              : undefined,
          rules:
            schedule.rules?.map((rule) => ({
              conditions:
                rule.conditions?.map((condition) => ({
                  valueType: condition.value_type ?? 'OCCUPANCY_COUNT',
                  operator: condition.operator ?? 'LTE',
                  value: condition.value ?? 0,
                  appliesTo: condition.applies_to ?? 'TOTAL',
                  channel:
                    condition.applies_to === 'CHANNEL'
                      ? {
                          agentId: condition.channel?.agent_id ?? '',
                          channelCategory:
                            condition.channel?.channel_category ??
                            'DIRECT_ONLINE',
                        }
                      : undefined,
                })) ?? [],
              action: {
                deltaType: rule.action?.unit_price_deltas?.length
                  ? rule.action?.unit_price_deltas[0].delta_type ?? 'DISCOUNT'
                  : 'DISCOUNT',
                percentOrFixed: rule.action?.unit_price_deltas?.length
                  ? rule.action?.unit_price_deltas[0].delta_percent
                    ? 'PERCENT'
                    : 'FIXED'
                  : 'PERCENT',
                channelType: rule.action?.channels?.length
                  ? 'PER_CHANNEL'
                  : 'ALL',
                channels:
                  rule.action?.channels?.map((channel) => ({
                    agentId: channel.agent_id ?? '',
                    channelCategory:
                      channel.channel_category ?? 'DIRECT_ONLINE',
                  })) ?? [],
                unitPriceDeltas:
                  rule.action?.unit_price_deltas?.map((delta) => ({
                    method: delta.unit_price_method ?? 'PER_BOOKING',
                    guestTypeKey: delta.guest_type_key ?? '',
                    deltaType: delta.delta_type ?? 'CHARGE',
                    deltaFixedGross: delta.delta_fixed_gross ?? '',
                    deltaFixedNet: delta.delta_fixed_net ?? '',
                    deltaPercent: delta.delta_percent ?? 0,
                  })) ?? [],
              },
            })) ?? [],
        })
      ) ?? [],
  };
};

export const convertFormValuesToProductPatch = (
  formValues: FormValues
): Product$Patch => {
  return {
    automatic_price_adjustment_settings: {
      schedules: formValues.schedules.map((schedule) => ({
        participation_date_ranges: schedule.participationDateRanges.map(
          (dateRange) => ({
            start_date_local: dateRange.startDate,
            end_date_local: dateRange.endDate,
          })
        ),
        participation_days_of_week: schedule.participationDaysOfWeek,
        applies_from_participation_relative_date_time:
          schedule.appliesFromParticipationRelativeDate
            ? convertToSwaggerRelativeDateTime(
                schedule.appliesFromParticipationRelativeDate
              )
            : undefined,
        applies_until_participation_relative_date_time:
          schedule.appliesUntilParticipationRelativeDate
            ? convertToSwaggerRelativeDateTime(
                schedule.appliesUntilParticipationRelativeDate
              )
            : undefined,
        rules: schedule.rules.map((rule) => ({
          conditions: rule.conditions.map((condition) => ({
            value_type: condition.valueType,
            operator: condition.operator,
            value: condition.value,
            applies_to: condition.appliesTo,
            channel:
              condition.appliesTo === 'CHANNEL'
                ? {
                    agent_id: condition.channel?.agentId,
                    channel_category: condition.channel?.channelCategory,
                  }
                : undefined,
          })),
          action: {
            unit_price_deltas: rule.action.unitPriceDeltas.map((delta) => ({
              unit_price_method: delta.method,
              guest_type_key: delta.guestTypeKey,
              delta_type: rule.action.deltaType,
              delta_percent:
                rule.action.percentOrFixed === 'PERCENT'
                  ? delta.deltaPercent
                  : undefined,
              delta_fixed_gross:
                rule.action.percentOrFixed === 'FIXED'
                  ? delta.deltaFixedGross
                  : undefined,
              delta_fixed_net:
                rule.action.percentOrFixed === 'FIXED'
                  ? delta.deltaFixedNet
                  : undefined,
            })),
            channels: rule.action.channels.map((channel) => ({
              agent_id: channel.agentId,
              channel_category: channel.channelCategory,
            })),
          },
        })),
      })),
    },
  };
};
