import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Delete } from 'client/components/Icons/Delete';
import {
  Select,
  DateRangeInput,
  FieldWrapper,
  OptionalIntegerInput,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Add } from 'client/components/Icons/Add';

import styles from './AdjustmentScheduleEditor.module.css';
import { StartTimeRelativeLimitsEditor } from './StartTimeRelativeLimitsEditor/StartTimeRelativeLimitsEditor';
import { Channel } from './formValues';

interface Props {
  name: string;
}

export const AdjustmentScheduleEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  const conditionValueTypeOptions = [
    {
      value: 'OCCUPANCY_PERCENT',
      text: t('Occupancy Percent'),
    },
    {
      value: 'OCCUPANCY_COUNT',
      text: t('Occupancy Count'),
    },
  ];
  const operatorOptions = [
    {
      value: 'LTE',
      text: t('Less than or equal to'),
    },
    {
      value: 'GTE',
      text: t('Greater than or equal to'),
    },
    {
      value: 'EQ',
      text: t('Equal to'),
    },
  ];
  const channelCategoryOptions = [
    {
      value: 'COMMON',
      text: t('Common'),
    },
    {
      value: 'DIRECT_ONLINE',
      text: t('DIRECT_ONLINE'),
    },
    {
      value: 'DIRECT_OFFLINE',
      text: t('DIRECT_OFFLINE'),
    },
    {
      value: 'AGENT',
      text: t('Agent'),
    },
  ];
  const actionTypeOptions = [
    {
      value: 'INCREASE_TOTAL',
      label: t('Increase total by amount'),
    },
    {
      value: 'DECREASE_TOTAL',
      label: t('Decrease total by amount'),
    },
    {
      value: 'SET_TOTAL',
      label: t('Set total'),
    },
    {
      value: 'CLOSE_CHANNEL',
      label: t('Close channel'),
    },
    {
      value: 'REMOVE_REMAINING_SLOTS',
      label: t('Remove all remaining slots'),
    },
    {
      value: 'TRANSFER_REMAINING_SLOTS',
      label: t('Transfer all remaining slots'),
    },
  ];

  const appliesToOptions = [
    {
      value: 'TOTAL',
      label: t('Total Occupancy'),
    },
    {
      value: 'CHANNEL',
      label: t('Specific Channel Dedicated Allotment'),
    },
  ];

  const editingProduct = React.useContext(EditingProductContext);
  const agentOptions =
    editingProduct?.agents?.map((agent) => ({
      value: agent.id,
      text: agent.name,
    })) ?? [];

  const form = useForm();
  const conditionValueType = _.get(
    form.getState().values,
    `${name}.rules[0].conditions[0].valueType`
  );
  const conditionAppliesTo = _.get(
    form.getState().values,
    `${name}.rules[0].conditions[0].appliesTo`
  );
  const conditionChannelCategory = _.get(
    form.getState().values,
    `${name}.rules[0].conditions[0].channel.channelCategory`
  );
  const actionType = _.get(
    form.getState().values,
    `${name}.rules[0].actions[0].actionType`
  );
  const actionChannels = _.get(
    form.getState().values,
    `${name}.rules[0].actions[0].channels`
  ) as Channel[];
  const fromChannel = _.get(
    form.getState().values,
    `${name}.rules[0].actions[0].fromChannel`
  ) as Channel;
  const toChannel = _.get(
    form.getState().values,
    `${name}.rules[0].actions[0].toChannel`
  ) as Channel;

  return (
    <div>
      <Field name={name}>
        {({ input }) => (
          <DateRangeInput
            label={t('Participation Date Range')}
            fromDate={input.value?.startDate}
            onChangeFromDate={(newStartDate) => {
              input.onChange({
                ...input.value,
                startDate: newStartDate,
              });
            }}
            toDate={input.value?.endDate}
            onChangeToDate={(newToDate) => {
              input.onChange({
                ...input.value,
                endDate: newToDate,
              });
            }}
          />
        )}
      </Field>
      <StartTimeRelativeLimitsEditor name={name} />
      <div className={styles['section-box__ttl']}>{t('Condition')}</div>
      <div className={styles['section-box']}>
        <Box display="flex" width="100%">
          <div style={{ marginTop: '25px' }}>
            <Field name={`${name}.rules.0.conditions.0.valueType`}>
              {({ input }) => (
                <Select
                  options={conditionValueTypeOptions}
                  value={input.value}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              )}
            </Field>
          </div>
          <div style={{ marginTop: '25px' }}>
            <Field name={`${name}.rules.0.conditions.0.operator`}>
              {({ input }) => (
                <Select
                  options={operatorOptions}
                  value={input.value}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              )}
            </Field>
          </div>
          <Box display="flex" alignItems="flex-end" width="100px">
            {conditionValueType === 'OCCUPANCY_PERCENT' && (
              <Field name={`${name}.rules.0.conditions.0.value`}>
                {({ input }) => (
                  <OptionalIntegerInput
                    label={t('Percent')}
                    value={input.value}
                    onChange={(value) => input.onChange(value)}
                  />
                )}
              </Field>
            )}
            {conditionValueType === 'OCCUPANCY_COUNT' && (
              <Field name={`${name}.rules.0.conditions.0.value`}>
                {({ input }) => (
                  <OptionalIntegerInput
                    label=""
                    value={input.value}
                    onChange={(value) => input.onChange(value)}
                  />
                )}
              </Field>
            )}
          </Box>
        </Box>
        <FieldWrapper label={t('Applies to')} />
        <EnumRadioButtonGroup
          name={`${name}.rules.0.conditions[0].appliesTo`}
          options={appliesToOptions}
        />
        {conditionAppliesTo === 'CHANNEL' && (
          <>
            <Box display="flex" mt={2} alignItems="flex-end">
              <Field
                name={`${name}.rules.0.conditions.0.channel.channelCategory`}
              >
                {({ input }) => (
                  <Select
                    label={t('Channel condition applies to')}
                    options={channelCategoryOptions}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
              {conditionChannelCategory === 'AGENT' && (
                <Field name={`${name}.rules.0.conditions.0.channel.agentId`}>
                  {({ input }) => (
                    <Select
                      options={agentOptions}
                      value={input.value}
                      onChange={(e, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              )}
            </Box>
          </>
        )}
      </div>
      <div className={styles['section-box__ttl']}>{t('Action')}</div>
      <div className={styles['section-box']}>
        <FieldWrapper label={t('Action Type')} />
        <EnumRadioButtonGroup
          name={`${name}.rules.0.actions[0].actionType`}
          options={actionTypeOptions}
          onChange={(value) => {
            if (value === 'CLOSE_CHANNEL') {
              form.change(
                `${name}.rules.0.actions[0].channels`,
                actionChannels.filter(
                  (channel) => channel.channelCategory !== 'COMMON'
                )
              );
            }
          }}
        />
        {(actionType === 'INCREASE_TOTAL' ||
          actionType === 'DECREASE_TOTAL' ||
          actionType === 'SET_TOTAL') && (
          <Field name={`${name}.rules.0.actions[0].amount`}>
            {({ input }) => (
              <OptionalIntegerInput
                label={t('Allotment')}
                value={input.value}
                onChange={(value) => input.onChange(value)}
              />
            )}
          </Field>
        )}
        {actionType === 'TRANSFER_REMAINING_SLOTS' && (
          <>
            <Box display="flex" alignItems="flex-end">
              <Field
                name={`${name}.rules.0.actions[0].fromChannel.channelCategory`}
              >
                {({ input }) => (
                  <Select
                    label={t('Source Channel')}
                    options={channelCategoryOptions}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
              {fromChannel?.channelCategory === 'AGENT' && (
                <Field name={`${name}.rules.0.actions[0].fromChannel.agentId`}>
                  {({ input }) => (
                    <Select
                      options={agentOptions}
                      value={input.value}
                      onChange={(e, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              )}
            </Box>
            <Box display="flex" alignItems="flex-end">
              <Field
                name={`${name}.rules.0.actions[0].toChannel.channelCategory`}
              >
                {({ input }) => (
                  <Select
                    label={t('Destination Channel')}
                    options={channelCategoryOptions}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
              {toChannel?.channelCategory === 'AGENT' && (
                <Field name={`${name}.rules.0.actions[0].toChannel.agentId`}>
                  {({ input }) => (
                    <Select
                      options={agentOptions}
                      value={input.value}
                      onChange={(e, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              )}
            </Box>
          </>
        )}
        {actionType !== 'TRANSFER_REMAINING_SLOTS' && (
          <>
            <FieldWrapper label={t('Channels to apply action to')} />
            <FieldArray name={`${name}.rules.0.actions[0].channels`}>
              {({ fields }) => (
                <>
                  {fields.map((field, idx) => (
                    <Box key={field} display="flex">
                      <Field name={`${field}.channelCategory`}>
                        {({ input }) => (
                          <Select
                            options={
                              actionType === 'CLOSE_CHANNEL'
                                ? channelCategoryOptions.filter(
                                    (option) => option.value !== 'COMMON'
                                  )
                                : channelCategoryOptions
                            }
                            value={input.value}
                            onChange={(e, { value }) => input.onChange(value)}
                          />
                        )}
                      </Field>
                      {fields.value[idx]?.channelCategory === 'AGENT' && (
                        <Field name={`${field}.agentId`}>
                          {({ input }) => (
                            <Select
                              options={agentOptions}
                              value={input.value}
                              onChange={(e, { value }) => input.onChange(value)}
                            />
                          )}
                        </Field>
                      )}
                      <Delete onClick={() => fields.remove(idx)} />
                    </Box>
                  ))}
                  <Add
                    onClick={() =>
                      (fields as any).insertAt(fields.value?.length ?? 0, {
                        channelCategory: 'DIRECT_ONLINE',
                        agentId: '',
                      })
                    }
                  />
                </>
              )}
            </FieldArray>
          </>
        )}
      </div>
    </div>
  );
};
