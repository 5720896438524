import * as React from 'react';
import { useFormState } from 'react-final-form';

import { FormValues } from './FormValues';
import { ProductController } from './ProductController';
import { ProductList } from './ProductList';
import styles from './StartTime.module.css';

interface Props {
  selectedScheduleKey: string | undefined;
  selectedStartTimeKey: string | undefined;
}

export const StartTime = ({
  selectedScheduleKey,
  selectedStartTimeKey,
}: Props) => {
  const { values } = useFormState<FormValues>();

  const selectedSchedule = React.useMemo(() => {
    return values.schedules.find(
      (schedule) => schedule.key === selectedScheduleKey
    );
  }, [values, selectedScheduleKey]);

  const selectedStartTimeMapping = React.useMemo(() => {
    return (selectedSchedule?.startTimeMappings || []).find(
      (mapping) => mapping.key === selectedStartTimeKey
    );
  }, [selectedSchedule, selectedStartTimeKey]);

  return (
    <div className={styles['p-frame']}>
      <div className={styles['p-frame__header']}>
        <p className={styles['p-frame__header__ttl']}>
          {selectedStartTimeMapping?.startTimeLocal}
        </p>
      </div>
      <div className={styles['p-frame__body']}>
        <ProductController />
        <ProductList />
      </div>
    </div>
  );
};
