import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { ProductSummary } from 'shared/models/swagger';
import { summariesSelector } from 'client/reducers/products';

import styles from './ParticipationRuleController.module.css';

export const ProductController = () => {
  const [selectedProductId, setSelectedProductId] = React.useState<
    string | undefined
  >();
  const { t } = useTranslation();
  const { values } = useFormState();
  const productSummaries = useSelector(summariesSelector);

  const options = React.useMemo(() => {
    if (!productSummaries) {
      return [];
    }

    return productSummaries.map((productSummary: ProductSummary) => {
      return {
        text: productSummary.product_name || '',
        value: productSummary.id || '',
      };
    });
  }, [values]);

  const addNewProduct = () => {
    console.log('addNewProduct');
  };

  return (
    <div>
      <div className={styles['p-equipmentScheduleEditor__rule__select']}>
        <p className={styles['p-equipmentScheduleEditor__rule__select__ttl']}>
          {t('Select')}
        </p>
        <div className={styles['p-equipmentScheduleEditor__rule__select__box']}>
          <SingleDropdown
            options={options}
            selectedOption={selectedProductId || ''}
            onChange={(e) => {
              setSelectedProductId(e);
            }}
          />
          <a
            className={styles['p-equipemntScheduleEditor__rule__add']}
            onClick={addNewProduct}
          >
            <i className="c-icon-outline-general-plus-circle"></i>
            {t('Add Participation Rule')}
          </a>
        </div>
      </div>
    </div>
  );
};
