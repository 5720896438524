import { useTranslation } from 'react-i18next';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { ProductGroupSettingBody } from 'client/pages/v3/Availability/ProductGroup/ProductGroupSetting/ProductGroupSettingBody';
import { V3Page } from 'client/components/v3/Page/V3Page';

export const ProductGroupSetting = () => {
  const { t } = useTranslation();

  return (
    <V3Page>
      <PageHeader title={t('Settings')}></PageHeader>
      <ProductGroupSettingBody />
    </V3Page>
  );
};
