import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from 'react-final-form';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { V3Page } from 'client/components/v3/Page/V3Page';
import { equipmentsSelector } from 'client/reducers/equipments';
import { getArrayMutators } from 'client/libraries/util/form';

import { ParticipationRuleController } from './ParticipationRuleController';
import { ParticipationRule } from './ParticipationRule';
import { FormValues, getInitialFormValues } from './FormValues';
import styles from './EquipmentScheduleEditor.module.css';

export const EquipmentScheduleEditor = () => {
  const [selectedScheduleKey, setSelectedScheduleKey] = React.useState<
    string | undefined
  >(undefined);

  const equipments = useSelector(equipmentsSelector);

  const { id } = useParams<{ id: string }>();

  const equipemnt = React.useMemo(() => {
    if (!equipments) {
      return undefined;
    }

    if (equipments.length === 0) {
      return undefined;
    }

    return equipments.find((equipment) => equipment.id === id);
  }, [equipments, id]);

  const initialValues = React.useMemo(() => {
    if (!equipemnt) {
      return undefined;
    }
    return getInitialFormValues(equipemnt);
  }, [equipemnt]);

  React.useEffect(() => {
    if (!initialValues) {
      return;
    }

    if ((initialValues?.schedules || []).length === 0) {
      return;
    }

    setSelectedScheduleKey(initialValues.schedules[0].key);
  }, [initialValues]);

  if (!equipemnt) {
    return <div>Loading...</div>;
  }

  return (
    <V3Page>
      <PageHeader title={equipemnt?.title || ''} />
      <div className={styles['main']}>
        <section className={styles['g-section']}>
          <div className={styles['p-equipmentScheduleEditor']}>
            <div className={styles['p-equipmentScheduleEditor__body']}>
              <Form<FormValues>
                initialValues={initialValues}
                onSubmit={(values: FormValues) => console.log(values)}
                mutators={getArrayMutators()}
              >
                {() => (
                  <>
                    <ParticipationRuleController
                      value={selectedScheduleKey}
                      onChange={setSelectedScheduleKey}
                    />
                    <ParticipationRule
                      selectedScheduleKey={selectedScheduleKey}
                    />
                  </>
                )}
              </Form>
            </div>
          </div>
        </section>
      </div>
    </V3Page>
  );
};
