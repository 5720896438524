import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { productOptionsSelector } from 'client/reducers/products';
import { fetchProducts } from 'client/actions/products';
import { fetchOrganizations } from 'client/actions/organizations';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Pagination } from 'client/components/v3/Pagination/Pagination';
import { AutomaticStopSaleProductGroupSetting } from 'shared/models/swagger';

import { ActionButton } from './ActionButton';
import styles from './ProductGroupList.module.css';

export const ProductGroupList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const allProductOptions = useSelector(productOptionsSelector);

  const productGroupSettings =
    activeOrganization?.automatic_stop_sale_product_group_settings ?? [];

  useEffect(() => {
    if (activeOrganization) {
      dispatch(fetchProducts());
    }
  }, [activeOrganization]);

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, []);

  // Table handler states and functions
  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageItems, setPageItems] = useState<
    AutomaticStopSaleProductGroupSetting[]
  >([]);
  const pageCount =
    Math.floor(productGroupSettings.length / rowCount) +
    (productGroupSettings.length % rowCount ? 1 : 0);
  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };
  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    if (productGroupSettings.length > 0) {
      const newPageItems = productGroupSettings.slice(
        (currentPage - 1) * rowCount,
        currentPage * rowCount
      );
      setPageItems(newPageItems);
    } else {
      if (pageItems.length != 0) {
        setPageItems([]);
      }
    }
  }, [rowCount, currentPage, productGroupSettings]);

  return (
    <div className={clsx(styles['p-tagSettings'])}>
      <div className={styles['p-tagSettings__header']}>
        <div className={styles['p-tagSettings__header__actions']}>
          <Link
            to={{
              pathname: `/availability/productGroup/edit`,
              state: {
                key: '',
              },
            }}
          >
            <Button text={t('Create Product Group')} />
          </Link>
        </div>
      </div>
      <div className={styles['p-tagSettings__body']}>
        {/* Note: initially intended to use PagedGenericTable, but due to clash of action button menu popup and table overflow,
            I decided to create table manually instead */}
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
        >
          <thead>
            <tr>
              <th className={baseStyles['u-width-288']}>
                {t('Products Group Title')}
              </th>
              <th>{t('Products')}</th>
              <th className={baseStyles['u-width-64']}>{t('Action')}</th>
            </tr>
          </thead>
          {(productGroupSettings.length === 0 || pageItems.length === 0) && (
            <tbody>
              <tr>
                <td colSpan={3} className={styles['noData']}>
                  {t(
                    'There are no items that match the specified search conditions'
                  )}
                </td>
              </tr>
            </tbody>
          )}
          {pageItems.length > 0 && (
            <tbody>
              {pageItems.map((setting, index) => {
                return (
                  <tr key={index}>
                    <td>{setting.title}</td>
                    <td data-text={t('Products')}>
                      {setting.product_ids?.map((id, idx) => {
                        const target = allProductOptions.find(
                          (p) => p.key == id
                        );
                        return <div key={idx}>{target?.text || ''}</div>;
                      })}
                    </td>
                    <td data-text={t('Action')}>
                      <ActionButton
                        productGroupSetting={setting}
                        lastItemOnList={
                          index + 1 === productGroupSettings.length
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className={styles['p-tagSettings__footer']}>
        <Pagination
          totalItems={productGroupSettings.length}
          currentPage={currentPage}
          pageCount={pageCount}
          selectedNumberOfLinesToDisplay={rowCount}
          onChangeNumberOfLinesToDisplay={setRowCount}
          onClickPrevButton={pageBackClickHandler}
          onClickNextButton={pageForwardClickHandler}
        />
      </div>
    </div>
  );
};
