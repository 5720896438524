import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import moment from 'moment-timezone';

import calendarIcon from 'client/images/ic_calendar.svg';
import {
  Select,
  FieldWrapper,
  OptionalIntegerInput,
  ToggleButton,
  Checkbox,
} from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { EnumRadioButtonGroup } from 'client/components/EnumRadioButtonGroup/EnumRadioButtonGroup';
import { Delete } from 'client/components/Icons/Delete';
import { Add } from 'client/components/Icons/Add';
import { getPriceSchedulesActiveInDateRange } from 'client/libraries/util/getPriceSchedulesActiveInDateRange';
import { FloatInput } from 'client/components/FloatInput';
import { MoneyInput } from 'client/components/MoneyInput/MoneyInput';
import { getProductCurrency } from 'client/libraries/util/getProductCurrency';
import baseStyles from 'client/base.module.css';
import { DateInput } from 'client/components/NewProductEditor/ReservationParamsSteps/DateInput';
import { WeekdaysInput } from 'client/components/NewProductEditor/WeekdaysInput/WeekdaysInput';

import styles from './AdjustmentScheduleEditor.module.css';
import { StartTimeRelativeLimitsEditor } from './StartTimeRelativeLimitsEditor/StartTimeRelativeLimitsEditor';
import { UnitPriceDelta } from './formValues';

interface Props {
  name: string;
}

export const AdjustmentScheduleEditor = ({ name }: Props) => {
  const { t } = useTranslation();
  const form = useForm();

  const conditionValueTypeOptions = [
    {
      value: 'OCCUPANCY_PERCENT',
      text: t('Occupancy Percent'),
    },
    {
      value: 'OCCUPANCY_COUNT',
      text: t('Occupancy Count'),
    },
  ];
  const operatorOptions = [
    {
      value: 'LTE',
      text: t('Less than or equal to'),
    },
    {
      value: 'GTE',
      text: t('Greater than or equal to'),
    },
    {
      value: 'EQ',
      text: t('Equal to'),
    },
  ];
  const channelCategoryOptions = [
    {
      value: 'COMMON',
      text: t('Common'),
    },
    {
      value: 'DIRECT_ONLINE',
      text: t('DIRECT_ONLINE'),
    },
    {
      value: 'DIRECT_OFFLINE',
      text: t('DIRECT_OFFLINE'),
    },
    {
      value: 'AGENT',
      text: t('Agent'),
    },
  ];

  const appliesToOptions = [
    {
      value: 'TOTAL',
      label: t('Total Occupancy'),
    },
    {
      value: 'CHANNEL',
      label: t('Specific Channel Dedicated Allotment'),
    },
  ];
  const priceAdjustPercentOrFixedOptions = [
    {
      value: 'PERCENT',
      label: t('Percent'),
    },
    {
      value: 'FIXED',
      label: t('Fixed'),
    },
  ];
  const deltaTypeOptions = [
    {
      value: 'CHARGE',
      label: t('Charge'),
    },
    {
      value: 'DISCOUNT',
      label: t('Discount'),
    },
  ];

  const editingProduct = React.useContext(EditingProductContext);
  const productCurrency =
    (editingProduct && getProductCurrency(editingProduct)) ?? 'USD';

  const agentOptions =
    editingProduct?.agents?.map((agent) => ({
      value: agent.id,
      text: agent.name,
    })) ?? [];

  const conditionValueType = _.get(
    form.getState().values,
    `${name}.rules[0].conditions[0].valueType`
  );
  const conditionAppliesTo = _.get(
    form.getState().values,
    `${name}.rules[0].conditions[0].appliesTo`
  );
  const conditionChannelCategory = _.get(
    form.getState().values,
    `${name}.rules[0].conditions[0].channel.channelCategory`
  );
  const priceAdjustmentPercentOrFixed = _.get(
    form.getState().values,
    `${name}.rules[0].action.percentOrFixed`
  );
  const deltaType = _.get(
    form.getState().values,
    `${name}.rules[0].action.deltaType`
  );
  const startDateLocalFrom = _.get(
    form.getState().values,
    `${name}.rules[0].startDate`
  );

  const startDateLocalTo = _.get(
    form.getState().values,
    `${name}.rules[0].endDate`
  );

  React.useEffect(() => {
    const unitPriceMethod =
      priceSchedules[0].units.length > 0 &&
      priceSchedules[0].units[0].method === 'PER_BOOKING'
        ? 'PER_BOOKING'
        : 'PER_PARTICIPANT';

    const allGuestTypeKeys = [];
    if (unitPriceMethod === 'PER_PARTICIPANT') {
      allGuestTypeKeys.push(
        ...[
          ...new Set(
            _.flatten(
              priceSchedules.map((priceSched) =>
                priceSched.units
                  .map((unit) => (unit.guest_type ? unit.guest_type.key : ''))
                  .filter((key) => !!key)
              )
            )
          ),
        ]
      );
    }

    const existingDeltas: UnitPriceDelta[] =
      _.get(
        form.getState().values,
        `${name}.rules[0].action.unitPriceDeltas`
      ) ?? [];

    let newDeltas: UnitPriceDelta[] = [];
    if (unitPriceMethod === 'PER_BOOKING') {
      const existingDelta = existingDeltas?.find(
        (delta) => delta.method === 'PER_BOOKING'
      );

      newDeltas = [
        {
          method: 'PER_BOOKING',
          guestTypeKey: '',
          deltaFixedGross: existingDelta?.deltaFixedGross ?? '',
          deltaFixedNet: existingDelta?.deltaFixedNet ?? '',
          deltaPercent: existingDelta?.deltaPercent ?? 0,
        },
      ];
    } else {
      newDeltas = allGuestTypeKeys.map((guestTypeKey) => {
        const existingDelta = existingDeltas?.find(
          (delta) =>
            delta.method === 'PER_PARTICIPANT' &&
            delta.guestTypeKey === guestTypeKey
        );

        return {
          method: 'PER_PARTICIPANT',
          guestTypeKey,
          deltaFixedGross: existingDelta?.deltaFixedGross ?? '',
          deltaFixedNet: existingDelta?.deltaFixedNet ?? '',
          deltaPercent: existingDelta?.deltaPercent ?? 0,
        };
      });
    }

    form.change(`${name}.rules[0].action.unitPriceDeltas`, newDeltas);
  }, [editingProduct, form, name]);

  const priceSchedules = getPriceSchedulesActiveInDateRange(
    editingProduct?.pricing || [],
    startDateLocalFrom,
    startDateLocalTo
  );

  const unitPriceMethod =
    priceSchedules[0].units.length > 0 &&
    priceSchedules[0].units[0].method === 'PER_BOOKING'
      ? 'PER_BOOKING'
      : 'PER_PARTICIPANT';

  const allGuestTypeKeys = [];
  if (unitPriceMethod === 'PER_PARTICIPANT') {
    allGuestTypeKeys.push(
      ...[
        ...new Set(
          _.flatten(
            priceSchedules.map((priceSched) =>
              priceSched.units
                .map((unit) => (unit.guest_type ? unit.guest_type.key : ''))
                .filter((key) => !!key)
            )
          )
        ),
      ]
    );
  }

  const channelCategories = [
    {
      title: t('DIRECT_ONLINE'),
      value: 'DIRECT_ONLINE',
    },
    {
      title: t('DIRECT_OFFLINE'),
      value: 'DIRECT_OFFLINE',
    },
    {
      title: t('All Agents'),
      value: 'AGENT',
    },
  ];

  return (
    <div>
      <div className={styles['section-box__ttl']}>
        {t('Participation Dates')}
      </div>
      <FieldWrapper label={t('Date Ranges')} />
      <FieldArray name={`${name}.participationDateRanges`}>
        {({ fields }) => (
          <>
            {fields.length === 0 && (
              <Add
                onClick={() =>
                  (fields as any).insertAt(0, {
                    startDate: moment().format('YYYY-MM-DD'),
                    endDate: moment().add(1, 'years').format('YYYY-MM-DD'),
                  })
                }
              />
            )}
            {fields.map((name, idx) => (
              <Field key={name} name={name}>
                {({ input: dateRangeInput }) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    mb={2}
                  >
                    <Box flexShrink={0} width="280px">
                      {dateRangeInput.value.startDate ===
                      dateRangeInput.value.endDate ? (
                        <label className={baseStyles['base-form-calendar']}>
                          <img src={calendarIcon} />
                          <DateInput
                            name={`${name}.startDate`}
                            value={dateRangeInput.value.startDate}
                            onChange={(e) => {
                              dateRangeInput.onChange({
                                startDate: e.target.value,
                                endDate: e.target.value,
                              });
                            }}
                          />
                        </label>
                      ) : (
                        <div className={baseStyles['base-form-range']}>
                          <label className={baseStyles['base-form-calendar']}>
                            <img src={calendarIcon} />
                            <Field name={`${name}.startDate`}>
                              {({ input }) => (
                                <DateInput
                                  name={input.name}
                                  value={input.value}
                                  onChange={input.onChange}
                                />
                              )}
                            </Field>
                          </label>
                          <p>-</p>
                          <label className={baseStyles['base-form-calendar']}>
                            <img src={calendarIcon} />
                            <Field name={`${name}.endDate`}>
                              {({ input }) => (
                                <DateInput
                                  name={input.name}
                                  value={input.value}
                                  onChange={input.onChange}
                                />
                              )}
                            </Field>
                          </label>
                        </div>
                      )}
                    </Box>
                    <Box ml={2} flexShrink={0}>
                      <Field name={name}>
                        {({ input }) => (
                          <ToggleButton
                            label={t('1 day only')}
                            checked={
                              input.value.startDate &&
                              input.value.startDate === input.value.endDate
                            }
                            onChange={() => {
                              if (!input.value.startDate) return;

                              if (
                                input.value.startDate === input.value.endDate
                              ) {
                                input.onChange({
                                  ...input.value,
                                  endDate: moment(input.value.startDate)
                                    .add('years', 1)
                                    .format('YYYY-MM-DD'),
                                });
                              } else {
                                input.onChange({
                                  ...input.value,
                                  endDate: input.value.startDate,
                                });
                              }
                            }}
                          />
                        )}
                      </Field>
                    </Box>
                    <Box display="flex" alignItems="center" ml={2}>
                      <Add
                        onClick={() => {
                          (fields as any).insertAt(idx + 1, {
                            startDate: moment().format('YYYY-MM-DD'),
                            endDate: moment()
                              .add(1, 'years')
                              .format('YYYY-MM-DD'),
                          });
                        }}
                      />
                      <Box ml={1}>
                        <Delete
                          onClick={() => {
                            fields.remove(idx);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Field>
            ))}
          </>
        )}
      </FieldArray>
      <FieldWrapper label={t('Days of Week')} />
      <Field name={`${name}.participationDaysOfWeek`}>
        {({ input }) => (
          <WeekdaysInput value={input.value} onChange={input.onChange} />
        )}
      </Field>
      <StartTimeRelativeLimitsEditor name={name} />
      <div className={styles['section-box__ttl']}>{t('Condition')}</div>
      <div className={styles['section-box']}>
        <Box display="flex" width="100%">
          <div style={{ marginTop: '25px' }}>
            <Field name={`${name}.rules.0.conditions.0.valueType`}>
              {({ input }) => (
                <Select
                  options={conditionValueTypeOptions}
                  value={input.value}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              )}
            </Field>
          </div>
          <div style={{ marginTop: '25px' }}>
            <Field name={`${name}.rules.0.conditions.0.operator`}>
              {({ input }) => (
                <Select
                  options={operatorOptions}
                  value={input.value}
                  onChange={(e, { value }) => input.onChange(value)}
                />
              )}
            </Field>
          </div>
          <Box display="flex" alignItems="flex-end" width="100px">
            {conditionValueType === 'OCCUPANCY_PERCENT' && (
              <Field name={`${name}.rules.0.conditions.0.value`}>
                {({ input }) => (
                  <OptionalIntegerInput
                    label={t('Percent')}
                    value={input.value}
                    onChange={(value) => input.onChange(value)}
                  />
                )}
              </Field>
            )}
            {conditionValueType === 'OCCUPANCY_COUNT' && (
              <Field name={`${name}.rules.0.conditions.0.value`}>
                {({ input }) => (
                  <OptionalIntegerInput
                    label=""
                    value={input.value}
                    onChange={(value) => input.onChange(value)}
                  />
                )}
              </Field>
            )}
          </Box>
        </Box>
        <FieldWrapper label={t('Applies to')} />
        <EnumRadioButtonGroup
          name={`${name}.rules.0.conditions[0].appliesTo`}
          options={appliesToOptions}
        />
        {conditionAppliesTo === 'CHANNEL' && (
          <>
            <Box display="flex" mt={2} alignItems="flex-end">
              <Field
                name={`${name}.rules.0.conditions.0.channel.channelCategory`}
              >
                {({ input }) => (
                  <Select
                    label={t('Channel condition applies to')}
                    options={channelCategoryOptions}
                    value={input.value}
                    onChange={(e, { value }) => input.onChange(value)}
                  />
                )}
              </Field>
              {conditionChannelCategory === 'AGENT' && (
                <Field name={`${name}.rules.0.conditions.0.channel.agentId`}>
                  {({ input }) => (
                    <Select
                      options={agentOptions}
                      value={input.value}
                      onChange={(e, { value }) => input.onChange(value)}
                    />
                  )}
                </Field>
              )}
            </Box>
          </>
        )}
      </div>
      <div className={styles['section-box__ttl']}>{t('Price Adjustment')}</div>
      <div>
        <FieldWrapper label={t('Channels to apply action to')} />
        <Field name={`${name}.rules.0.action.channels`}>
          {({ input }) => {
            const selectedCategories = new Set(
              input.value.map((v: any) => v?.channelCategory).filter(Boolean)
            );

            const toggleCategory = (categoryValue: string) => {
              let newValue;

              if (selectedCategories.has(categoryValue)) {
                newValue = input.value.filter(
                  (v: any) => v?.channelCategory !== categoryValue
                );
              } else {
                newValue = [
                  ...input.value.filter((v: any) => v?.channelCategory),
                  { channelCategory: categoryValue },
                ];
              }

              input.onChange(newValue);
            };

            return (
              <Box display="flex" width="100%">
                {channelCategories.map((category, index) => (
                  <Box mb={2} mr={4} width="100%" key={index}>
                    <Checkbox
                      label={category.title}
                      checked={selectedCategories.has(category.value)}
                      onChange={() => toggleCategory(category.value)}
                    />
                  </Box>
                ))}
              </Box>
            );
          }}
        </Field>
        <FieldWrapper label={t('Delta Type')} />
        <EnumRadioButtonGroup
          name={`${name}.rules.0.action.deltaType`}
          options={deltaTypeOptions}
        />
        <FieldWrapper label={t('Percent/Fixed Amount')} />
        <EnumRadioButtonGroup
          name={`${name}.rules.0.action.percentOrFixed`}
          options={priceAdjustPercentOrFixedOptions}
        />
        <FieldWrapper label={t('Adjustment Amounts')} />
        <FieldArray name={`${name}.rules.0.action.unitPriceDeltas`}>
          {({ fields }) => {
            return (
              <>
                {fields.map((fieldName, idx) => {
                  const unitTitle =
                    unitPriceMethod === 'PER_BOOKING'
                      ? t('Per-Booking')
                      : fields.value[idx].guestTypeKey;

                  return priceAdjustmentPercentOrFixed === 'PERCENT' ? (
                    <Field name={`${fieldName}.deltaPercent`}>
                      {({ input }) => (
                        <FloatInput
                          label={`${unitTitle}:${
                            deltaType === 'CHARGE'
                              ? t('Percent Charge (Gross/Net)')
                              : t('Percent Discount (Gross/Net)')
                          }`}
                          value={input.value}
                          onChange={(newPercent) => {
                            if (0 <= newPercent && newPercent <= 100) {
                              input.onChange(newPercent);
                            }
                          }}
                        />
                      )}
                    </Field>
                  ) : (
                    <>
                      <FieldWrapper
                        label={`${unitTitle}:${
                          deltaType === 'CHARGE'
                            ? t('Charge Amount (Gross)')
                            : t('Discount Amount (Gross)')
                        }`}
                      >
                        <Field name={`${fieldName}.deltaFixedGross`}>
                          {({ input }) => (
                            <MoneyInput
                              currencyCode={productCurrency}
                              moneyAmount={input.value}
                              onChange={(newValue) => input.onChange(newValue)}
                            />
                          )}
                        </Field>
                      </FieldWrapper>
                      <FieldWrapper
                        label={`${unitTitle}:${
                          deltaType === 'CHARGE'
                            ? t('Charge Amount (Net)')
                            : t('Discount Amount (Net)')
                        }`}
                      >
                        <Field name={`${fieldName}.deltaFixedNet`}>
                          {({ input }) => (
                            <MoneyInput
                              currencyCode={productCurrency}
                              moneyAmount={input.value}
                              onChange={(newValue) => input.onChange(newValue)}
                            />
                          )}
                        </Field>
                      </FieldWrapper>
                    </>
                  );
                })}
              </>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
