import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { RangeEditor } from './RangeEditor';
import { StartTimeAdder } from './StartTimeAdder';
import { StartTimeSelector } from './StartTimeSelector';
import { StartTime } from './StartTime';
import styles from './Schedule.module.css';

interface Props {
  name: string;
  selectedScheduleKey: string | undefined;
}

export const Schedule = ({ name, selectedScheduleKey }: Props) => {
  const [selectedStartTimeKey, setSelectedStartTimeKey] =
    React.useState<string>('');
  const { t } = useTranslation();
  return (
    <div className={styles['p-frame']}>
      <div className={styles['p-frame__header']}>
        <p className={styles['p-frame__header__ttl']}>
          {t('Selected Participation Rule')}
        </p>
      </div>
      <div className={styles['p-frame__body']}>
        <ul className={styles['p-list']}>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <div className={styles['p-list__item__ttl__txt']}>
                <div>{t('Date Range')}</div>
              </div>
            </div>
            <div className={styles['p-list__item__body']}>
              <RangeEditor name={name} />
            </div>
          </li>
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <div className={styles['p-list__item__ttl__txt']}>
                <div>{t('Start Time')}</div>
              </div>
            </div>
            <div className={styles['p-list__item__body']}>
              <StartTimeAdder name={name} />
            </div>
          </li>
        </ul>
        <StartTimeSelector
          selectedScheduleKey={selectedScheduleKey}
          value={selectedStartTimeKey}
          onChange={(newValue) => {
            setSelectedStartTimeKey(newValue);
          }}
        />
        <StartTime
          selectedScheduleKey={selectedScheduleKey}
          selectedStartTimeKey={selectedStartTimeKey}
        />
      </div>
    </div>
  );
};
