import * as React from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';

import { Schedule } from './FormValues';
import styles from './ParticipationRuleController.module.css';

interface Props {
  value: string | undefined;
  onChange: (newValue: string) => void;
}

export const ParticipationRuleController = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const { values } = useFormState();
  const form = useForm();

  const options = React.useMemo(() => {
    if (!values) {
      return [];
    }

    return values.schedules.map((schedule: Schedule) => {
      return {
        text: `${schedule.startDateLocal} - ${schedule.endDateLocal}`,
        value: schedule.key,
      };
    });
  }, [values]);

  const addNewSchedule = () => {
    const key = uuidv4();
    form.change('schedules', [
      ...values.schedules,
      {
        key: key,
        startDateLocal: '',
        endDateLocal: '',
      },
    ]);
    onChange(key);
  };

  return (
    <div>
      <div className={styles['p-equipmentScheduleEditor__rule__select']}>
        <p className={styles['p-equipmentScheduleEditor__rule__select__ttl']}>
          {t('Select')}
        </p>
        <div className={styles['p-equipmentScheduleEditor__rule__select__box']}>
          <SingleDropdown
            options={options}
            selectedOption={value || ''}
            onChange={(e) => {
              onChange(e);
            }}
          />
          <a
            className={styles['p-equipemntScheduleEditor__rule__add']}
            onClick={addNewSchedule}
          >
            <i className="c-icon-outline-general-plus-circle"></i>
            {t('Add Participation Rule')}
          </a>
        </div>
      </div>
    </div>
  );
};
