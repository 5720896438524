import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Fab from 'client/components/Fab/Fab';
import {
  createInternalChat,
  fetchInternalChats,
} from 'client/actions/internalChats';
import { loadConversationId } from 'client/reducers/internalChats';
import { ReduxState } from 'client/reducers';
import {
  activeUserOrganizationSelector,
  activeUserSelector,
  loggedInAccountIsNutmegAdminSelector,
} from 'client/reducers/user';
import { LanguageUppercaseISO } from 'shared/libraries/i18n';
import logo from 'client/images/logo_chat.svg';

import closeIcon from '../../images/chat/close.svg';

import styles from './InternalChat.module.css';
import { MessageProps } from './types';
import { ChatInput } from './ChatInput';
import { Message } from './Message';
import { AnimatedClickableDiv } from './AnimatedClickableDiv';
import { Loader } from './Loader';

// Reverse usage of switch found in src/client/pages/PrivateMarketplace/PrivateMarketplaceMarketingAutomation/PrivateMarketplaceMarketingAutomation.tsx
// Replace this if there is already an existing util method
const convertLanguageISOToSourceLanguage = (languageIso: string) => {
  let sourceLanguage = '';
  switch (languageIso) {
    case 'ja':
      sourceLanguage = 'JA_JP';
      break;

    case 'ko':
      sourceLanguage = 'KO_KR';
      break;

    case 'zh-TW':
      sourceLanguage = 'ZH_TW';
      break;

    case 'zh-CN':
      sourceLanguage = 'ZH_CN';
      break;

    case 'en':
    default:
      sourceLanguage = 'EN_US';
  }
  return sourceLanguage;
};

export const InternalChat: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const messageRefs = React.useRef<
    {
      el: HTMLDivElement;
      isUser: boolean;
    }[]
  >([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [messages, setMessages] = React.useState<MessageProps[]>([]);
  const [conversationId, setConversationId] = React.useState<string>('');

  const all = useSelector((state: ReduxState) => state.internalChats.all);
  const loading = useSelector(
    (state: ReduxState) => state.internalChats.loading
  );
  const activeUser = useSelector(activeUserSelector);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  // const chatSettings = useSelector(
  //   (reduxState: ReduxState) => reduxState.supplierSettings.chat
  // );
  const loggedInAccountIsNutmegAdmin = useSelector(
    loggedInAccountIsNutmegAdminSelector
  );

  const handleSendMessage = (inputMessage: string): void => {
    if (inputMessage.trim() !== '' && conversationId) {
      dispatch(
        createInternalChat({
          conversation_id: conversationId,
          question: inputMessage,
          language: convertLanguageISOToSourceLanguage(
            locale
          ) as LanguageUppercaseISO,
        })
      );

      setMessages((prevMessages) => [
        ...prevMessages,
        { text: inputMessage, isUser: true },
      ]);
    }
  };

  const handleOpen = (): void => setOpen(true);

  // Get supplier settings for chat
  // React.useEffect(() => {
  //   if (activeUser && activeOrganization) {
  //     dispatch(fetchSupplierSettings('CHAT'));
  //   }
  // }, [activeUser, activeOrganization]);

  // Get chat data
  React.useEffect(() => {
    const conversationId = loadConversationId(
      activeOrganization?.id ?? '',
      activeUser?.id ?? '',
      loggedInAccountIsNutmegAdmin
    );
    if (conversationId) {
      setConversationId(conversationId);
      dispatch(fetchInternalChats(conversationId));
    }
  }, [activeUser, activeOrganization]);

  React.useEffect(() => {
    const messages: MessageProps[] = [];

    if (!all) {
      return;
    }

    messages.push({ text: t('Hello! How can I help you?'), isUser: false });

    all.forEach((chat) => {
      messages.push({ text: chat.question ?? '', isUser: true });
      messages.push({ text: chat.answer ?? '', isUser: false });
    });

    setMessages(messages);
  }, [all]);

  React.useLayoutEffect(() => {
    // Whenever messages, loading state, or open state changes, scroll the most recent user message into view
    const userMessage = messageRefs.current.findLast((ref) => ref.isUser);
    if (userMessage) {
      userMessage.el?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, loading, open]);

  const mql = window.matchMedia('(max-width: 768px)');

  // Don't show chatbot if not enabled
  // if (!chatSettings?.enable_chatbot_internal?.value) {
  //   return <></>;
  // }

  // Don't show chatbot if logged user not admin
  if (!loggedInAccountIsNutmegAdmin) {
    return <></>;
  }

  return (
    <>
      {!open ? (
        <Fab
          color="primary"
          aria-label="chat"
          onClick={handleOpen}
          style={{
            position: 'fixed',
            bottom: mql.matches ? 90 : 72,
            right: mql.matches ? 16 : 32,
            zIndex: 1000,
            backgroundColor: '#DDE8FE',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
          }}
          icon={<img src={logo} width={40} />}
        ></Fab>
      ) : (
        <div className={styles.overlay}>
          <div className={styles.chatModal}>
            <div className={styles.header}>
              <AnimatedClickableDiv
                className={styles.closeButton}
                clickedClassName={styles.clicked}
                onClick={() => setOpen(false)}
              >
                <img src={closeIcon} className={styles.closeIcon} alt="close" />
              </AnimatedClickableDiv>
            </div>
            <div className={styles.messagesList}>
              {messages.map((message, index) => (
                <Message
                  key={index}
                  {...message}
                  ref={(el) =>
                    (messageRefs.current[index] = {
                      el: el as HTMLDivElement,
                      isUser: message.isUser ?? false,
                    })
                  }
                />
              ))}
              {loading && (
                <div className={styles.loadingArea}>
                  <Loader />
                </div>
              )}
            </div>
            <ChatInput onSendMessage={handleSendMessage} />
          </div>
        </div>
      )}
    </>
  );
};
