import clsx from 'clsx';
import * as React from 'react';
import { useFormState } from 'react-final-form';

import { FormValues } from './FormValues';
import styles from './StartTimeSelector.module.css';

interface Props {
  selectedScheduleKey: string | undefined;
  value: string | undefined;
  onChange: (newValue: string) => void;
}

export const StartTimeSelector = ({
  selectedScheduleKey,
  value,
  onChange,
}: Props) => {
  const { values } = useFormState<FormValues>();

  const selectedSchedule = React.useMemo(() => {
    return values.schedules.find(
      (schedule) => schedule.key === selectedScheduleKey
    );
  }, [values, selectedScheduleKey]);

  const startTimeMappings = React.useMemo(() => {
    return (selectedSchedule?.startTimeMappings || []).sort(
      (mappingA, mappingB) => {
        return mappingA.startTimeLocal.localeCompare(mappingB.startTimeLocal);
      }
    );
  }, [selectedSchedule]);

  console.log('startTimeMappings', startTimeMappings);

  return (
    <div>
      <ul className={styles['list']}>
        {startTimeMappings.map((mapping) => {
          return (
            <li
              key={mapping.key}
              className={clsx(
                styles['item'],
                value === mapping.key ? styles['is_active'] : null
              )}
            >
              <button
                onClick={() => {
                  onChange(mapping.key);
                }}
              >
                {mapping.startTimeLocal}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
