import * as React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_ERROR } from 'final-form';
import { Form, Field } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { FieldArray } from 'react-final-form-arrays';
import createDecorator from 'final-form-focus';
import { useTranslation } from 'react-i18next';

import { productOptionsSelector } from 'client/reducers/products';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { fetchProducts } from 'client/actions/products';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { TextField } from 'client/components/v3/Form/TextField';
import { Button } from 'client/components/v3/Common/Button';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { ScrollToContext } from 'client/contexts/ScrollToContext';
import { Loading } from 'client/pages/Loading';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { updateOrganization } from 'client/actions/organizations';
import type { ReduxState } from 'client/reducers';
import { getArrayMutators } from 'client/libraries/util/form';
import baseStyles from 'client/v3-base.module.css';
import * as Swagger from 'shared/models/swagger';

import styles from './ProductGroupSetting.module.css';
import { CollapsibleSection } from './CollapsibleSection/CollapsibleSection';
import { CollapsibleBox } from './CollapsibleSection/CollapsibleBox';
import type {
  SiteSettingsFormValues,
  StartTime,
  AutomaticStopSaleProductStartTimeList,
} from './utils';
import { getInitialValues, getAvailabilityRuleScheduleText } from './utils';

interface LocationState {
  key: string;
}

const focusOnError: any = createDecorator();

export const ProductGroupSettingBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const allProductOptions = useSelector(productOptionsSelector);
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const scrollTo = React.useContext(ScrollToContext);

  const location = useLocation();
  const { key } = location.state as LocationState;

  const [saveSucceeded, setSaveSucceeded] = React.useState<boolean>(false);

  const productGroupSettings =
    activeUserOrganization?.automatic_stop_sale_product_group_settings;
  const productOptions = allProductOptions.filter((o) => {
    if ((productGroupSettings || []).length == 0) {
      return true;
    }
    return productGroupSettings?.some(
      (p) =>
        (p.key == key && p?.product_ids?.includes(o.key)) ||
        (p.key != key && !p?.product_ids?.includes(o.key))
    );
  });

  React.useEffect(() => {
    if (activeUserOrganization) {
      dispatch(fetchProducts());
    }
  }, [activeUserOrganization]);

  if (!activeUserOrganization) {
    return <Loading />;
  }

  return (
    <div className={baseStyles['l-main__body']}>
      <div>
        <Form
          onSubmit={async (values: SiteSettingsFormValues) => {
            const orgId = activeUserOrganization.id;
            let productGroupSettings = [
              ...(activeUserOrganization.automatic_stop_sale_product_group_settings ??
                []),
            ];
            const targetSetting = productGroupSettings?.find(
              (s) => s.key === values.key
            );
            const newSetting: Swagger.AutomaticStopSaleProductGroupSetting = {
              key: values.key,
              title: values.title,
              product_ids: values.productIds,
              automatic_stop_sale_product_start_time_settings: (
                values?.automaticStopSaleProductStartTimeSettings || []
              ).map((aSetting) => ({
                automatic_stop_sale_product_start_time_lists: (
                  aSetting?.automaticStopSaleProductStartTimeLists || []
                ).map((sSetting) => ({
                  product_id: sSetting.productId,
                  recurrence_key: sSetting.recurrenceKey,
                  start_times: (sSetting?.startTimes || []).map((sTime) => ({
                    start_time_local: sTime.startTimeLocal,
                    duration: sTime.duration,
                    time_slot_key: sTime.timeSlotKey,
                  })),
                })),
              })),
            };
            if (!targetSetting) {
              productGroupSettings.push(newSetting);
            } else {
              productGroupSettings = productGroupSettings.map((p) => {
                if (p.key == values.key) {
                  return newSetting;
                }
                return p;
              });
            }

            try {
              await dispatch(
                updateOrganization(orgId, 'SUPPLIER', {
                  automatic_stop_sale_product_group_settings:
                    productGroupSettings,
                })
              );

              scrollTo(0, 0);
              setSaveSucceeded(true);
            } catch (err) {
              return {
                [FORM_ERROR]: t('Save Failed'),
              };
            }
          }}
          decorators={[focusOnError]}
          initialValues={{
            ...getInitialValues(key || '', activeUserOrganization),
          }}
          mutators={getArrayMutators()}
          keepDirtyOnReinitialize={true}
        >
          {({ handleSubmit, values, submitting, form }) => {
            const defaultAutomaticStopSaleProductStartTimeSettings = [
              {
                automaticStopSaleProductStartTimeLists: values.productIds.map(
                  (p) => ({
                    productId: p,
                    recurrenceKey: '',
                    startTimes: [],
                  })
                ),
              },
            ];
            const changeProductId = (productId: string) => {
              const tmpSetting = [
                ...(values.automaticStopSaleProductStartTimeSettings || []),
              ];
              if (productId != '') {
                for (let i = 0; i < tmpSetting.length; i++) {
                  const ok = tmpSetting[
                    i
                  ]?.automaticStopSaleProductStartTimeLists?.some(
                    (item2) => item2 && item2.productId == productId
                  );
                  if (!ok && tmpSetting[i]) {
                    tmpSetting[i]?.automaticStopSaleProductStartTimeLists?.push(
                      {
                        productId: productId,
                        recurrenceKey: '',
                        startTimes: [],
                      }
                    );
                  }
                }
                form.change(
                  `automaticStopSaleProductStartTimeSettings` as any,
                  tmpSetting
                );
              }
            };
            const deleteProductId = (productId: string) => {
              const tmpSetting = [
                ...(values.automaticStopSaleProductStartTimeSettings || []),
              ];
              if (productId != '') {
                for (let i = 0; i < tmpSetting.length; i++) {
                  const ok = tmpSetting[
                    i
                  ]?.automaticStopSaleProductStartTimeLists?.some(
                    (item2) => item2 && item2.productId == productId
                  );
                  if (ok && tmpSetting[i]) {
                    tmpSetting[i].automaticStopSaleProductStartTimeLists = (
                      tmpSetting[i]?.automaticStopSaleProductStartTimeLists ||
                      []
                    )?.filter((l) => l.productId !== productId);
                  }
                }
                form.change(
                  `automaticStopSaleProductStartTimeSettings` as any,
                  tmpSetting
                );
              }
            };
            return (
              <form onSubmit={handleSubmit}>
                {saveSucceeded && (
                  <Snackbar
                    text={t('Save Successful')}
                    color="success"
                    shouldShow={saveSucceeded}
                    onClose={() => setSaveSucceeded(false)}
                  />
                )}
                <section className={styles['g-section']}>
                  <CollapsibleSection
                    id="default setting"
                    title={t('Default Settings')}
                  >
                    <ul className={styles['p-general-list']}>
                      <li className={styles['p-general-list__item']}>
                        <div className={styles['p-general-list__item__ttl']}>
                          <div
                            className={styles['p-general-list__item__ttl__txt']}
                          >
                            <div>{t('Product Group Name')}</div>
                          </div>
                        </div>
                        <div className={styles['p-general-list__item__body']}>
                          <div
                            className={
                              styles['p-general-products__section__body__item']
                            }
                          >
                            <Field name="title">
                              {({ input }) => <TextField {...input} />}
                            </Field>
                          </div>
                        </div>
                      </li>
                      <li className={styles['p-general-list__item']}>
                        <div className={styles['p-general-list__item__ttl']}>
                          <div
                            className={styles['p-general-list__item__ttl__txt']}
                          >
                            <div>{t('Product')}</div>
                          </div>
                        </div>
                        <div className={styles['p-general-list__item__body']}>
                          <div
                            className={
                              styles['p-general-products__section__body__item']
                            }
                          >
                            <FieldArray name="productIds">
                              {({ fields }) => {
                                const sectionCount = fields.length ?? 0;
                                return (
                                  <>
                                    {fields.map((name, idx) => {
                                      return (
                                        <>
                                          <div style={{ marginBottom: '10px' }}>
                                            <div style={{ display: 'flex' }}>
                                              <Field name={`${name}`}>
                                                {({ input }) => (
                                                  <SingleDropdown
                                                    searchable={true}
                                                    placeholder={t(
                                                      'Target Product'
                                                    )}
                                                    selectedOption={input.value}
                                                    options={productOptions.filter(
                                                      (p) =>
                                                        (p &&
                                                          p.value ===
                                                            input.value) ||
                                                        !values.productIds.includes(
                                                          p.value
                                                        )
                                                    )}
                                                    onChange={async (value) => {
                                                      const oldId =
                                                        fields.value?.[idx];
                                                      await deleteProductId(
                                                        oldId
                                                      );
                                                      await changeProductId(
                                                        value
                                                      );
                                                      input.onChange(value);
                                                    }}
                                                  />
                                                )}
                                              </Field>
                                              <Button
                                                size="icon"
                                                color="tertiarygray"
                                                iconBeforeText={
                                                  <i className="c-icon-outline-general-trash-03"></i>
                                                }
                                                onClick={() => {
                                                  const id =
                                                    fields.value?.[idx];
                                                  deleteProductId(id);
                                                  fields.remove(idx);
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}
                                    <a
                                      className={styles['add__button']}
                                      onClick={() => {
                                        if (sectionCount === 0) {
                                          (fields as any).insertAt(0, '');
                                        } else {
                                          (fields as any).insertAt(
                                            sectionCount + 1,
                                            ''
                                          );
                                        }
                                      }}
                                    >
                                      <i className="c-icon-outline-general-plus-circle"></i>
                                      {t('Add Product')}
                                    </a>
                                  </>
                                );
                              }}
                            </FieldArray>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </CollapsibleSection>
                  <CollapsibleSection
                    id="auto stop group"
                    title={t('Auto Stop Group')}
                    subtitle={t('auto stop')}
                  >
                    <ul className={styles['p-general-list']}>
                      <li
                        className={styles['p-general-list__item']}
                        style={{ width: '100%' }}
                      >
                        <div
                          className={styles['p-general-list__item__body']}
                          style={{ width: '100%' }}
                        >
                          <div
                            className={
                              styles['p-general-products__section__body__item']
                            }
                          >
                            <FieldArray name="automaticStopSaleProductStartTimeSettings">
                              {({ fields }) => {
                                const sectionCount = fields.length ?? 0;
                                return (
                                  <>
                                    {fields.map((name, idx) => {
                                      return (
                                        <>
                                          <CollapsibleBox
                                            key={name}
                                            id={name}
                                            title={`${t(
                                              'Auto Stop Setting'
                                            )} #${idx + 1}
                                            `}
                                            isDeleteable={true}
                                            onDelete={async () => {
                                              fields.remove(idx);
                                            }}
                                            isMovable={false}
                                          >
                                            <FieldArray
                                              name={`${name}.automaticStopSaleProductStartTimeLists`}
                                            >
                                              {({ fields: settingFields }) => {
                                                return (
                                                  <>
                                                    {settingFields.map(
                                                      (name2, idx2) => {
                                                        const targetProduct =
                                                          allProducts.find(
                                                            (product) =>
                                                              settingFields
                                                                .value?.[idx2]
                                                                ?.productId ==
                                                              product.id
                                                          );
                                                        return (
                                                          <div
                                                            className={
                                                              styles[
                                                                'p-general-list__item'
                                                              ]
                                                            }
                                                            key={idx2}
                                                          >
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                  'center',
                                                                width: '100%',
                                                              }}
                                                            >
                                                              <div
                                                                className={
                                                                  styles[
                                                                    'p-general-list__item__ttl'
                                                                  ]
                                                                }
                                                              >
                                                                <div
                                                                  className={
                                                                    styles[
                                                                      'p-general-list__item__ttl__txt'
                                                                    ]
                                                                  }
                                                                >
                                                                  <div>
                                                                    {productOptions.find(
                                                                      (o) =>
                                                                        o.value ==
                                                                        targetProduct?.id
                                                                    )?.text ||
                                                                      ''}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  width: '100%',
                                                                }}
                                                              >
                                                                <Field
                                                                  name={`${name2}.recurrenceKey`}
                                                                >
                                                                  {({
                                                                    input,
                                                                  }) => {
                                                                    return (
                                                                      <SingleDropdown
                                                                        optionOpensAt="top"
                                                                        placeholder={t(
                                                                          'Date Range'
                                                                        )}
                                                                        selectedOption={
                                                                          input.value
                                                                        }
                                                                        options={(
                                                                          targetProduct?.recurrence ||
                                                                          []
                                                                        ).map(
                                                                          (
                                                                            r
                                                                          ) => ({
                                                                            key:
                                                                              r.key ||
                                                                              '',
                                                                            value:
                                                                              r.key ||
                                                                              '',
                                                                            text: getAvailabilityRuleScheduleText(
                                                                              r,
                                                                              t
                                                                            ),
                                                                          })
                                                                        )}
                                                                        onChange={(
                                                                          value
                                                                        ) => {
                                                                          input.onChange(
                                                                            value
                                                                          );
                                                                        }}
                                                                      />
                                                                    );
                                                                  }}
                                                                </Field>
                                                                <Field
                                                                  name={`${name2}.startTimes`}
                                                                >
                                                                  {({
                                                                    input,
                                                                  }) => {
                                                                    const rKey =
                                                                      settingFields
                                                                        .value?.[
                                                                        idx2
                                                                      ]
                                                                        .recurrenceKey;
                                                                    const invalidStartTime: string[] =
                                                                      [];
                                                                    for (
                                                                      let i = 0;
                                                                      i <
                                                                      fields
                                                                        ?.value
                                                                        ?.length;
                                                                      i++
                                                                    ) {
                                                                      fields.value?.[
                                                                        i
                                                                      ].automaticStopSaleProductStartTimeLists?.forEach(
                                                                        (
                                                                          tl: AutomaticStopSaleProductStartTimeList
                                                                        ) => {
                                                                          if (
                                                                            idx !=
                                                                              i &&
                                                                            tl.productId ==
                                                                              targetProduct?.id &&
                                                                            tl.recurrenceKey ==
                                                                              rKey
                                                                          ) {
                                                                            (
                                                                              tl.startTimes ||
                                                                              []
                                                                            ).forEach(
                                                                              (
                                                                                st
                                                                              ) =>
                                                                                invalidStartTime.push(
                                                                                  st.startTimeLocal ||
                                                                                    ''
                                                                                )
                                                                            );
                                                                          }
                                                                        }
                                                                      );
                                                                    }
                                                                    const targetRecurrence =
                                                                      targetProduct?.recurrence?.find(
                                                                        (r) =>
                                                                          r.key ==
                                                                          rKey
                                                                      );
                                                                    return (
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            'flex',
                                                                          marginTop:
                                                                            '10px',
                                                                        }}
                                                                      >
                                                                        {targetRecurrence?.start_times?.map(
                                                                          (
                                                                            sTime,
                                                                            tIdx
                                                                          ) => {
                                                                            const checked =
                                                                              (
                                                                                input.value ||
                                                                                []
                                                                              ).find(
                                                                                (
                                                                                  s: StartTime
                                                                                ) =>
                                                                                  s.startTimeLocal ==
                                                                                  sTime.start_time_local
                                                                              );
                                                                            return (
                                                                              <div
                                                                                key={
                                                                                  tIdx
                                                                                }
                                                                                style={{
                                                                                  marginRight:
                                                                                    '10px',
                                                                                }}
                                                                              >
                                                                                <Checkbox
                                                                                  label={
                                                                                    sTime.start_time_local
                                                                                  }
                                                                                  disabled={invalidStartTime.includes(
                                                                                    sTime.start_time_local
                                                                                  )}
                                                                                  checked={Boolean(
                                                                                    checked
                                                                                  )}
                                                                                  onChange={() => {
                                                                                    if (
                                                                                      (
                                                                                        input.value ||
                                                                                        []
                                                                                      ).find(
                                                                                        (
                                                                                          s: StartTime
                                                                                        ) =>
                                                                                          s.startTimeLocal ==
                                                                                          sTime.start_time_local
                                                                                      )
                                                                                    ) {
                                                                                      input.onChange(
                                                                                        input.value?.filter(
                                                                                          (
                                                                                            s: StartTime
                                                                                          ) =>
                                                                                            s.startTimeLocal !==
                                                                                            sTime.start_time_local
                                                                                        )
                                                                                      );
                                                                                    } else {
                                                                                      input.onChange(
                                                                                        [
                                                                                          ...(input.value as StartTime[]),
                                                                                          {
                                                                                            startTimeLocal:
                                                                                              sTime.start_time_local,
                                                                                            duration:
                                                                                              sTime.duration,
                                                                                            timeSlotKey:
                                                                                              sTime.time_slot_key,
                                                                                          },
                                                                                        ]
                                                                                      );
                                                                                    }
                                                                                  }}
                                                                                  size="md"
                                                                                />
                                                                              </div>
                                                                            );
                                                                          }
                                                                        )}
                                                                      </div>
                                                                    );
                                                                  }}
                                                                </Field>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                );
                                              }}
                                            </FieldArray>
                                          </CollapsibleBox>
                                        </>
                                      );
                                    })}
                                    <a
                                      className={styles['add__button']}
                                      onClick={() => {
                                        if (sectionCount === 0) {
                                          (fields as any).insertAt(
                                            0,
                                            ...defaultAutomaticStopSaleProductStartTimeSettings
                                          );
                                        } else {
                                          (fields as any).insertAt(
                                            sectionCount + 1,
                                            ...defaultAutomaticStopSaleProductStartTimeSettings
                                          );
                                        }
                                      }}
                                    >
                                      <i className="c-icon-outline-general-plus-circle"></i>
                                      {t('Add Auto Stop Product Group')}
                                    </a>
                                  </>
                                );
                              }}
                            </FieldArray>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </CollapsibleSection>
                </section>
                <div
                  className={clsx(
                    styles['p-general-products__fixed'],
                    styles['is-active']
                  )}
                >
                  <div className={styles['p-general-products__fixed__main']}>
                    <Button
                      text={t('Save')}
                      type="submit"
                      color="primary"
                      size="md"
                      loading={submitting}
                      style={{
                        width: '60px',
                        fontWeight: 'var(--text-semibold)',
                      }}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </div>
  );
};
