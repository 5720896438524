import { v4 as uuid } from 'uuid';

import * as Swagger from 'shared/models/swagger';
import type { TranslateFuncType } from 'client/components/Translate';
import { getWeekdayText } from 'client/libraries/util/weekdays';

export type StartTime = {
  startTimeLocal?: string;
  duration?: string;
  timeSlotKey?: string;
};

export type AutomaticStopSaleProductStartTimeList = {
  productId?: string;
  recurrenceKey?: string;
  startTimes?: StartTime[];
};

export type AutomaticStopSaleProductStartSetting = {
  automaticStopSaleProductStartTimeLists?: AutomaticStopSaleProductStartTimeList[];
};

export type SiteSettingsFormValues = {
  key: string;
  title: string;
  productIds: string[];
  automaticStopSaleProductStartTimeSettings?: AutomaticStopSaleProductStartSetting[];
};

const convertAutomaticStopSaleProductStartSettingConvertToFormValue = (
  setting: Swagger.AutomaticStopSaleProductStartTimeSetting
): AutomaticStopSaleProductStartSetting => {
  return {
    automaticStopSaleProductStartTimeLists: (
      setting.automatic_stop_sale_product_start_time_lists ?? []
    ).map((s) => {
      return {
        productId: s.product_id || '',
        recurrenceKey: s.recurrence_key || '',
        startTimes: (s.start_times ?? []).map((time) => {
          return {
            startTimeLocal: time.start_time_local || '',
            duration: time.duration || '',
            timeSlotKey: time.time_slot_key || '',
          };
        }),
      };
    }),
  };
};

export const getInitialValues = (
  key: string,
  org: Swagger.Organization
): SiteSettingsFormValues => {
  const productGroupSettings = org.automatic_stop_sale_product_group_settings;
  const setting = productGroupSettings?.find((s) => s.key === key);

  if (setting) {
    return {
      key: setting?.key || '',
      title: setting?.title || '',
      productIds: setting?.product_ids || [],
      automaticStopSaleProductStartTimeSettings: (
        setting?.automatic_stop_sale_product_start_time_settings || []
      ).map((s) =>
        convertAutomaticStopSaleProductStartSettingConvertToFormValue(s)
      ),
    };
  }

  return {
    key: uuid(),
    title: '',
    productIds: [],
    automaticStopSaleProductStartTimeSettings: [
      { automaticStopSaleProductStartTimeLists: [] },
    ],
  };
};

export const getAvailabilityRuleScheduleText = (
  schedule: Swagger.Recurrence,
  t: TranslateFuncType
): string => {
  const weekdayText =
    schedule.days_of_week.length >= 7
      ? t('Everyday')
      : schedule.days_of_week
          .map((weekday) => getWeekdayText(weekday, t))
          .join(',');
  const dateRanges = [
    {
      startDate: schedule?.start_date_local,
      endDate: schedule?.end_date_local,
    },
    ...(schedule?.additional_date_ranges ?? []).map((dateRange) => ({
      startDate: dateRange?.start_date_local ?? '',
      endDate: dateRange?.end_date_local ?? '',
    })),
  ];

  if (dateRanges && dateRanges.length > 0) {
    return `${dateRanges
      .map((dateRange) => {
        if (dateRange.startDate && dateRange.endDate) {
          if (dateRange.startDate === dateRange.endDate) {
            return dateRange.startDate;
          } else {
            return `${dateRange.startDate} ~ ${dateRange.endDate}`;
          }
        }

        if (dateRange.startDate) {
          return `${dateRange.startDate} ~ ${t('Unknown')}`;
        }

        if (dateRange.endDate) {
          return `${t('Unknown')} ~ ${dateRange.endDate}`;
        }
      })
      .join(',')} - ${weekdayText}`;
  }

  return weekdayText;
};
