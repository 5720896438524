import { v4 as uuidv4 } from 'uuid';

import type {
  Equipment,
  EquipmentStartTimeMapping,
} from 'shared/models/swagger';

export interface FormValues {
  schedules: Schedule[];
}

export interface Schedule {
  key: string;
  startDateLocal: string;
  endDateLocal: string;
  startTimeMappings: StartTimeMapping[];
}

export interface StartTimeMapping {
  key: string;
  startTimeLocal: string;
  productStartTimes: ProductStartTime[];
}

export interface ProductStartTime {
  productId: string;
  startTime: Record<string, string>;
}

const convertProductStartTimesToFormValues = (
  mapping: EquipmentStartTimeMapping
): ProductStartTime[] => {
  const grouped: Record<string, Record<string, string>> = {};

  (mapping.product_start_times || []).forEach((item) => {
    const productId = item.product_id;
    const recurrenceKey = item.recurrence_key;
    const timeSlotKey = item.time_slot_key;

    if (!productId || !recurrenceKey || !timeSlotKey) return;

    if (!grouped[productId]) {
      grouped[productId] = {};
    }

    grouped[productId][recurrenceKey] = timeSlotKey;
  });

  return Object.entries(grouped).map(([productId, startTime]) => ({
    productId,
    startTime,
  }));
};

export const getInitialFormValues = (
  equipment: Equipment | null
): FormValues => {
  return {
    schedules: (equipment?.equipment_schedules || []).map((schedule) => {
      return {
        key: schedule.key || uuidv4(),
        startDateLocal: schedule.start_date_local || '',
        endDateLocal: schedule.end_date_local || '',
        startTimeMappings: (schedule.start_time_mappings || []).map(
          (mapping) => {
            return {
              key: mapping.key || uuidv4(),
              startTimeLocal: mapping.start_time_local || '',
              productStartTimes: convertProductStartTimesToFormValues(mapping),
            };
          }
        ),
      };
    }),
  };
};

export const convertToSwagger = (
  equipment: Equipment,
  values: FormValues
): Equipment => {
  return {
    ...equipment,
    equipment_schedules: values.schedules.map((schedule) => {
      return {
        start_date_local: schedule.startDateLocal,
        end_date_local: schedule.endDateLocal,
      };
    }),
  };
};
